import React, { useEffect, useState } from "react";
import bluelogo from "../images/EM-LOGO_Blue.png";
import "../CSS/schedule.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import axios from "axios";

const ScheduleFactoryVisit = () => {
  const [bookedSlots, setBookedSlots] = useState(0);
  const [error, setError] = useState(null);
  const [currentTime, setCurrentTime] = useState("");
  const [slotSelected, setSlotSelected] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [organization, setOrganization] = useState("");
  const [designation, setDesignation] = useState("");
  const [reason, setReason] = useState("");

  // Define time slots enum
  const timeSlots = ["11:00am", "03:30pm"];

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      // Fetch current time in Indian format (IST)
      const indianTime = now.toLocaleString("en-IN", {
        timeZone: "Asia/Kolkata", // India Standard Time (IST)
        hour: "2-digit",
        minute: "2-digit",
        hour12: true, // AM/PM format
      });
      setCurrentTime(indianTime);
    };

    // Update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    // Fetch booked slots from the API
    const fetchBookedSlots = async () => {
      try {
        const response = await axios.get("/api/factory/existing-slot-times");
        setBookedSlots(response.data.count);
      } catch (err) {
        setError("Failed to fetch booked slots.");
        console.error(err);
      } finally {
        setLoading(false); // Set loading to false when data is fetched
      }
    };

    fetchBookedSlots();
  }, []);

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("/api/factory/book-slot", {
        name,
        email,
        phoneNumber,
        designation,
        organization,
        reason,
        slotTime: slotSelected,
      });
      if (response) {
        alert("The event has been booked successfully!");
        window.location.href = "/";
      }
    } catch (error) {}
  };

  return (
    <>
      {!loading && (
        <div className="factory-schedule-booking">
          <div className="d-flex justify-content-center my-4 mb-lg-5">
            <img
              src={bluelogo}
              className="img-fluid"
              style={{ width: "150px" }}
              alt=""
            />
          </div>
          {
            <>
              <div className="booking-div mx-lg-auto rounded-4 mx-3 py-2">
                <div className="left-section p-lg-4 p-3">
                  <h1 className="poppins-semi-bold">Dynem Factory Visit</h1>
                  <div className="my-4 py-lg-2">
                    <div
                      className="d-flex align-items-center mb-3"
                      style={{ gap: "0.5em" }}
                    >
                      <Icon
                        icon="lucide:calendar"
                        width="20"
                        height="20"
                        style={{ color: "#757272" }}
                      />
                      <p className="m-0 ms-1 text-muted">
                        {slotSelected && <>{slotSelected},</>} Friday, February
                        7th 2025
                      </p>
                    </div>
                    <div
                      className="d-flex align-items-center mb-3"
                      style={{ gap: "0.5em" }}
                    >
                      <Icon
                        icon="humbleicons:location"
                        width="55"
                        height="55"
                        style={{ color: "#757272" }}
                      />
                      <a
                        href="https://www.google.com/maps/place/DYNEM+Gigafactory/@18.6459949,73.7136921,17z/data=!4m6!3m5!1s0x3bc2bdfb06c800e3:0xf9c92da79fc327dc!8m2!3d18.6459641!4d73.7137457!16s%2Fg%2F11y56nwhvp?hl=en&entry=ttu&g_ep=EgoyMDI1MDEyOS4xIKXMDSoASAFQAw%3D%3D"
                        className="text-decoration-none"
                        style={{ color: "inherit" }}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <p className="m-0 text-muted">
                          DYNEM Gigafactory<br></br>At post Jambe, taluka
                          Mulshi, 169/2, Mamurdi Sangavade Rd, Pune, Maharashtra
                          411033
                        </p>
                      </a>
                    </div>
                    {showForm && (
                      <>
                        <div
                          className="d-flex align-items-center"
                          style={{ gap: "0.5em" }}
                        >
                          <Icon
                            icon="mdi:world"
                            width="20"
                            height="20"
                            style={{ color: "#757272" }}
                          />
                          <p className="m-0 ms-1 text-muted">
                            Indian Standard Time
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/dynem-24/homepage/desktop/banners/Banner+1+Desktop.jpg"
                    className="img-fluid rounded-3"
                  ></img>
                </div>
                <div className="right-section p-lg-4 p-3">
                  {bookedSlots === 30 && (
                    <>
                      <div className="poppins-medium"></div>
                      <h3 className="poppins-medium text-danger">
                        Fully Booked – No Slots Available
                      </h3>
                      <p>
                        All slots for the Dynem Gigafactory visit on 7th
                        February 2025 have been filled. Stay tuned for future
                        opportunities!
                      </p>
                    </>
                  )}
                  {!showForm && bookedSlots !== 30 && (
                    <>
                      <div className="mb-3">
                        <small className="w-100 poppins-semi-bold">
                          No. of slots remaining:{" "}
                          <span className="text-em">{30 - bookedSlots}/30</span>
                        </small>
                      </div>
                      <h3 className="poppins-medium">Select time</h3>
                      <div className="my-4 py-lg-2">
                        <div
                          className="d-flex flex-wrap align-items-center"
                          style={{ gap: "0.5em" }}
                        >
                          {timeSlots.map((slot, index) => {
                            const isSelected = slot === slotSelected;
                            return (
                              <div
                                key={index}
                                className="time-tab btn metropolis-medium rounded-5"
                                style={{
                                  backgroundColor: isSelected ? "#004dac" : "",
                                  color: isSelected ? "white" : "",
                                  border: isSelected ? "1px solid #004dac" : "",
                                }}
                                onClick={() => {
                                  setSlotSelected(slot);
                                }}
                              >
                                {slot}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-center mb-3"
                        style={{ gap: "0.5em" }}
                      >
                        <Icon
                          icon="mdi:world"
                          width="20"
                          height="20"
                          style={{ color: "#000" }}
                        />
                        <p className="m-0">
                          Indian Standard Time ({currentTime})
                        </p>
                      </div>
                    </>
                  )}
                  {showForm && (
                    <>
                      <h3 className="poppins-medium">Enter Details</h3>
                      <div className="my-4 py-lg-2">
                        <form onSubmit={submitForm}>
                          <div className="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Name*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              required
                              value={name}
                              pattern="[A-Za-z\s]{2,}"
                              onChange={(e) => {
                                setName(e.target.value);
                                e.target.setCustomValidity("");
                              }}
                              style={{
                                textTransform: " capitalize",
                              }}
                              onInvalid={(e) =>
                                e.target.setCustomValidity(
                                  "Name should have at least 2 characters and contain only alphabets."
                                )
                              }
                            ></input>
                          </div>
                          <div className="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Email*
                            </label>
                            <input
                              value={email}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                              type="email"
                              className="form-control"
                              required
                            ></input>
                          </div>
                          <div className="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Phone Number*
                            </label>
                            <input
                              value={phoneNumber}
                              onChange={(e) => {
                                setPhoneNumber(e.target.value);
                              }}
                              type="tel"
                              className="form-control"
                              required
                            ></input>
                          </div>
                          <div
                            className="mb-3 d-flex align-items-center"
                            style={{ gap: "0.5em" }}
                          >
                            <div className="w-50">
                              <label
                                for="exampleFormControlInput1"
                                className="form-label"
                              >
                                Organization*
                              </label>
                              <input
                                type="text"
                                value={organization}
                                onChange={(e) => {
                                  setOrganization(e.target.value);
                                }}
                                className="form-control"
                                required
                              ></input>
                            </div>
                            <div className="w-50">
                              <label
                                for="exampleFormControlInput1"
                                className="form-label"
                              >
                                Designation*
                              </label>
                              <input
                                type="text"
                                value={designation}
                                onChange={(e) => {
                                  setDesignation(e.target.value);
                                }}
                                className="form-control"
                                required
                              ></input>
                            </div>
                          </div>
                          <div className="mb-3">
                            <label
                              for="exampleFormControlInput1"
                              className="form-label"
                            >
                              Reason to visit*
                            </label>
                            <textarea
                              type="text"
                              value={reason}
                              onChange={(e) => {
                                setReason(e.target.value);
                              }}
                              className="form-control"
                              required
                            ></textarea>
                          </div>
                          <button
                            type="submit"
                            className="w-100 btn btn-primary"
                          >
                            Schedule
                          </button>
                        </form>
                      </div>
                    </>
                  )}
                  {!showForm && slotSelected && (
                    <div className="d-flex justify-content-center">
                      <div
                        className="btn btn-dark rounded-5 px-5 py-2"
                        onClick={() => {
                          setShowForm(true);
                        }}
                      >
                        Next
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          }
        </div>
      )}
    </>
  );
};

export default ScheduleFactoryVisit;
