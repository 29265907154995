import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import Footer from "../Components/Footer";
import "../CSS/cycle-vs-ecycle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import FormPopUpModal from "../Components/FormPopUp";
import "../CSS/form.css";
import FormTestRide from "../Components/FormTestRide";
import bluelogo from "../images/EM-LOGO_Blue.png";

const CycleVsEcycle = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  SwiperCore.use([Navigation, Pagination]);
  const [gclid, setGclid] = useState("");

  useEffect(() => {
    // Fetching UTM campaign parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const GClid = urlParams.get("gclid");
    // Set the gclid in the state
    if (GClid) {
      setGclid(GClid);
    }
  }, []);

  return (
    <>
      <div
        className="position-sticky navigation w-100"
        style={{ zIndex: "99", top: "0%" }}
      >
        <div className=" desktop-nav m-0 p-0 p-3 ps-lg-5 pe-lg-5 ">
          <div className="d-flex justify-content-center">
            <img
              src={bluelogo}
              className="img-fluid"
              style={{ width: "170px" }}
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="cycle-vs-ecycle">
        {/*hero banner */}
        <div className="hero-banner position-relative">
          <picture className="main-banner-img">
            <source
              media="(max-width:650px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/MOBILE+VIEW+BANNER.png"
            />
            <source
              media="(min-width:651px)"
              srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/DESKTOP+VIEW+BANNER.png"
            />
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/DESKTOP+VIEW+BANNER.png"
              alt="t-Rex+banner"
              className="d-block w-100"
            />
          </picture>
          <div className="position-absolute banner-cta-text d-none d-lg-block">
            <h1
              className="text-uppercase text-em"
              style={{ fontFamily: "Metropolis-bold" }}
            >
              PERFECT ELECTRIC PARTNER
            </h1>
            <h4 style={{ fontSize: "1.2em" }} className="text-center">
              FOR WORKOUTS, DAILY COMMUTES, AND KIDS!
            </h4>
            <div className="d-flex justify-content-center align-items-center ">
              <FormTestRide gclid={gclid} />
            </div>
          </div>
          <div className="position-absolute w-100 banner-cta-text-mb d-lg-none">
            <div className="d-flex justify-content-center">
              <h1
                className="text-uppercase text-em"
                style={{ fontFamily: "Metropolis-bold", fontSize: "1.4em" }}
              >
                PERFECT ELECTRIC PARTNER
              </h1>
            </div>
            <h4 className="text-center" style={{ fontSize: "0.8em" }}>
              FOR WORKOUTS, DAILY COMMUTES, AND KIDS!
            </h4>
          </div>

          <div
            className="position-absolute banner-div-curved d-flex justify-content-center align-items-center"
            onClick={() => setIsModalOpen(true)}
            style={{ cursor: "pointer" }}
          >
            <div className="background-em text-light px-4 py-3 px-lg-5 text-center">
              <h1 className="m-0 banner-text">GET E-CYCLE STARTING AT ₹999*</h1>
            </div>
          </div>
        </div>
        <div className="mt-5 d-flex justify-content-between align-items-center flex-column w-100 banner-cta-btn-mb d-lg-none">
          <FormTestRide gclid={gclid} />
        </div>
        <div className="mt-3" style={{ height: "10px" }}></div>
        <div className="cycle-vs-ecycle-line-2 mt-5"></div>
        {/* what is ecycle */}
        <div className="px-3 px-lg-5 my-4 my-lg-5">
          <h2
            className="px-lg-0 mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            E-CYCLE, WHAT IS THAT?
          </h2>
          <div>
            <p className="mb-2">
              It looks like a cycle but it rides like a bike!
            </p>
            <ul>
              <li>Has pedal assist which makes it easier for kids to ride.</li>
              <li>
                Helps pedal longer, perfect for fitness enthusiasts looking for
                more. 
              </li>
              <li>
                Perfect for daily commutes, just accelerate like a bike and zip
                through traffic! 
              </li>
            </ul>
          </div>
        </div>
        <div className="cycle-vs-ecycle-line-2"></div>

        {/*why emotorad*/}
        <div className="px-3 px-lg-5 my-4 my-lg-5">
          <h2
            className="px-lg-0 mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            WHY EMOTORAD?
          </h2>
          <p>
            We are not saying we are the best, but yes, we are. With over 85k
            riders nationwide, our carefully crafted e-cycles cater to every
            need. Pedal or accelerate, it’s that easy!
          </p>
        </div>

        {/* cycle vs ecycle */}
        <div className="cycle-vs-ecycle-line-2"></div>
        <div className="px-lg-5 my-4 my-lg-5">
          <div className="comparison-landing">
            <h2
              className="px-3 px-lg-0 mb-3"
              style={{ fontFamily: "Metropolis-bold" }}
            >
              E-CYCLE V/S CYCLE
            </h2>
            <div className="d-flex comparison-div">
              <div className="col col-md-6 first-div pe-lg-3">
                <picture className="main-banner-img mt-4 mt-lg-0">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+MOBILE.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+DESKTOP.png"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/CYCLE+COMPARISON+DESKTOP.png"
                    alt="t-Rex+banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="comparison-points px-3 my-3">
                  <ul>
                    <li className="custom-bullet">
                      <p className="mb-0">Difficult to pedal uphill</p>
                    </li>
                    <li className="custom-bullet">
                      <p className="mb-0">Requires more physical effort</p>
                    </li>
                    <li className="custom-bullet">
                      <p className="mb-0">More tiring for longer commutes</p>
                    </li>
                    <li className="custom-bullet">
                      <p className="mb-0">
                        Minimal electronics; traditional design
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
              <hr
                className="d-lg-none"
                style={{ borderTop: "2px solid", opacity: "0.7", margin: "0" }}
              ></hr>
              <div className="col col-md-6  ps-lg-3 ">
                <picture className="main-banner-img">
                  <source
                    media="(max-width:650px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCL_E+COMPARISON+MOBILE.png"
                  />
                  <source
                    media="(min-width:651px)"
                    srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+DESKTOP.png"
                  />
                  <img
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/E-CYCLE+COMPARISON+DESKTOP.png"
                    alt="t-Rex+banner"
                    className="d-block w-100"
                  />
                </picture>
                <div className="comparison-points px-3 my-lg-3 mt-3">
                  <ul>
                    <li className="custom-bullet">
                      <p className="mb-0">
                        Electric motor with battery assist, easier to ride
                      </p>
                    </li>
                    <li className="custom-bullet mb-0">
                      <p className="mb-0">
                        Has Pedal assist for easier pedalling
                      </p>
                    </li>
                    <li className="custom-bullet">
                      <p className="mb-0">
                        Pedal or accelerate, sweat by choice
                      </p>
                    </li>
                    <li className="custom-bullet">
                      <p className="mb-0">
                        Modern tech features like digital displays and more
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            onClick={() => setIsModalOpen(true)}
            className=" text-light mx-auto bg-gradient w-100 d-flex justify-content-center align-items-center w-fit  mt-4 mt-lg-5"
            style={{ fontFamily: "Metropolis-medium", cursor: "pointer" }}
          >
            <div className="background-em  w-fit d-inline-block px-4 py-2 text-uppercase rounded-3">
              BOOK A TEST RIDE
            </div>
          </div>
        </div>

        <div className="cycle-vs-ecycle-line-2"></div>
        {/* feature */}
        <div className="px-lg-5 px-3 my-5 features-comparison">
          <h2
            className=" text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            Less Effort, Longer Journeys!
          </h2>

          <div className="d-lg-none">
            <video
              autoPlay
              muted
              loop
              disableRemotePlayback
              playsInline
              className="w-100"
            >
              <source src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Trex+air+yellow_1.mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.3}
            initialSlide={1}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 5,
                spaceBetween: 20,
                centeredSlides: false,
                navigation: { clickable: false },
                arrows: false,
              },
            }}
            className="inside-div"
          >
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Pedal+asiist+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Pedal Assist Technology
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Effortless Rides
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#pedalAssistModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/battery+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Removable Battery
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Charge Anywhere
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#batteryModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Group+427320688.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Multifunctional Display
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Stay In Control
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#displayModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Suspension+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Dual Suspension
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Comfort Redefined
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#suspensionModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Range+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    80+ km Range
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Made For Commutes
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#rangeModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Frame+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Foldable Frame
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Compact & Portable
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#frameModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Warrenty+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Reliable Warranty
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Peace Of Mind
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#warrantyModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                className="rounded-4 p-3"
                style={{ backgroundColor: "#f0f0f2" }}
              >
                <div className="mb-2 mb-lg-0">
                  <img
                    className="w-50"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/Tyre+icon.png"
                  ></img>
                </div>
                <br className="d-none d-lg-block"></br>
                <div className="">
                  <h2
                    className="text-em"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    Fat Tyre Option
                  </h2>
                  <h2 style={{ fontFamily: "Metropolis-bold" }}>
                    Conquer The Roads
                  </h2>
                </div>
                <div className="d-flex justify-content-end">
                  <Icon
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#fatTyreModal"
                    icon="uiw:plus-circle"
                    width="1.7em"
                    height="1.7em"
                    style={{ color: "#004dac", cursor: "pointer" }}
                  />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* display modal */}
        <div
          class="modal fade"
          id="displayModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Stay ahead with comprehensive real-time information at a
                  glance. It offers essential data such as speed, distance
                  travelled, battery level, and assist mode selection, enhancing
                  the rider's control and convenience while ensuring a safer and
                  more informed cycling experience.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* pedal assist modal */}
        <div
          class="modal fade"
          id="pedalAssistModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Enhance the riding experience by automatically providing
                  assistance based on pedalling effort. This feature promotes
                  effortless cycling, especially on inclines or longer journeys,
                  offering a seamless blend of human power and electric
                  assistance for a smoother and more enjoyable ride.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* warranty modal */}
        <div
          class="modal fade"
          id="warrantyModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Comprehensive Coverage from frames to batteries and electrical
                  components, our warranty has you covered every step of the
                  way. Ride worry-free at every pedal!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* frame modal */}
        <div
          class="modal fade"
          id="frameModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Also comes with a foldable frame, making it easier to carry
                  anywhere you want. Unfold and zip through traffic with ease.
                  It’s as lightweight as it is stylish. Whether you are
                  commuting or using it for recreational rides, its robust build
                  has you back wherever you go!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* fat tyre modal */}
        <div
          class="modal fade"
          id="fatTyreModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Want to own a fat-tyre electric cycle? We have that too! Take
                  the roads not taken, unlock new adventures and unparalleled
                  fun!
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* range modal */}
        <div
          class="modal fade"
          id="rangeModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Provides extended travel distances on a single charge. This
                  capability enables riders to cover longer distances
                  effortlessly, making our electric cycles ideal for commuting
                  and recreational rides without the physical exertion required
                  by traditional cycles.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* suspension modal */}
        <div
          class="modal fade"
          id="suspensionModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  Absorbs bumps and vibrations from uneven terrain, enhancing
                  comfort and stability. It improves ride quality by minimising
                  impact on the rider, making it suitable for varied road
                  conditions and ensuring a smoother, more enjoyable cycling
                  experience overall.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* battery modal */}
        <div
          class="modal fade"
          id="batteryModal"
          tabindex="-1"
          aria-labelledby="displayModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div
              class="modal-content text-center text-light"
              style={{ backgroundColor: "#424242" }}
            >
              <div class="modal-body p-3">
                <div className="d-flex justify-content-end mb-3">
                  <Icon
                    icon="zondicons:minus-solid"
                    width="1.2em"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    height="1.2em"
                    style={{ color: "white", cursor: "pointer" }}
                  />
                </div>
                <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                  The removable battery feature allows for convenient charging
                  and storage. Easily detachable, it helps hassle-free
                  recharging at home or office, ensuring uninterrupted rides
                  without the need for complex infrastructure, making it ideal
                  for urban mobility. Charge like a smartphone, literally!
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="cycle-vs-ecycle-line-2"></div>

        {/* usps */}
        <div className="px-lg-5 px-3 my-5">
          <h2 className=" mb-3" style={{ fontFamily: "Metropolis-bold" }}>
            STARTING AT JUST ₹999!*
          </h2>
          <p className="mb-4">
            Get your favourite EMotorad e-cycle starting at ₹999* with just one
            click! Select your preferred model, and enjoy doorstep delivery
            within 2 days! Save time and money with peace of mind at every step!
          </p>
          <div
            className="d-flex justify-content-between ecycle-cycle-usp"
            style={{ gap: "2em", fontFamily: "Metropolis-medium" }}
          >
            <div className="background-em text-light px-5 py-3 text-center w-100 rounded-5">
              72 HRS RESOLUTION
            </div>
            <div className="background-em text-light px-5 py-3 text-center w-100 rounded-5">
              BEST-IN CLASS AFTER-SALES
            </div>
            <div className="background-em text-light px-5 py-3 text-center w-100 rounded-5">
              NO-COST EMI
            </div>
          </div>
        </div>

        <div className="cycle-vs-ecycle-line-2"></div>

        {/* FAQs */}
        <div className="cycle-vs-ecycle-faq px-lg-5 px-3 my-5">
          <h2 className=" mb-3" style={{ fontFamily: "Metropolis-bold" }}>
            FAQs
          </h2>
          <div
            class="accordion"
            id="accordionExample"
            style={{ fontFamily: "Metropolis-Light" }}
          >
            <div class="accordion-item mb-2 mb-lg-3">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  Can the battery be removed from the electric cycle?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  Yes, you can easily recharge the battery indoors or in a
                  secure location, rather than needing to bring the entire
                  electric cycle to a charging point. Charge anywhere,
                  literally!
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line"></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What if the chain of an e-cycle comes off?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  No worries! Unlike the geared cycle, an electric cycle comes
                  with a throttle which doesn’t depend on the chain. So whatever
                  the journey, stopping is not an option!
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line"></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Does an electric cycle need servicing too?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  Yes, an electric cycle does require periodic servicing to
                  maintain optimal performance and ensure longevity.
                  Professional servicing helps prevent issues and ensures safe
                  and reliable operation of your e-cycle.
                </div>
              </div>
            </div>
            <div className="cycle-vs-ecycle-line "></div>
            <div class="accordion-item my-2 my-lg-3">
              <h2 class="accordion-header" id="headingFour">
                <button
                  class="accordion-button px-0 collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  What are the long-term cost benefits of owning an e-cycle
                  compared to a geared cycle?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body px-0">
                  E-cycles also come with geared options so you don’t miss out
                  on anything. Reduced need for public transportation and an
                  affordable way to commute makes it far superior to a normal
                  geared cycle. You save more than just money, you save energy
                  too!
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* reviews */}
        <div className="px-lg-5 px-3 my-5">
          <h2
            className="text-uppercase mb-3"
            style={{ fontFamily: "Metropolis-bold" }}
          >
            hear from the tribe
          </h2>
          <Swiper
            navigation={{ clickable: true }}
            spaceBetween={15}
            slidesPerView={1.3}
            initialSlide={2}
            centeredSlides={false}
            loop={true}
            breakpoints={{
              1024: {
                slidesPerView: 4,
                spaceBetween: 20,
                centeredSlides: false,
                navigation: { clickable: false },
                arrows: false,
                initialSlide: 4,
                allowTouchMove: false,
                loop: false,
                slide: false,
                noSwiping: true,
              },
            }}
            slide
          >
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-1.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-1-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Karan Joshi
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review1Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-2.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-2-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Kalpana Jain
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review2Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-3.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-3-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Sukhveer Singh
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review3Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className="">
              <div className="">
                <div>
                  <img
                    className="w-100"
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                    alt="reviews"
                    src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/cycle+vs+ecycle/review-4.jpg"
                  ></img>
                </div>
                <div
                  className="p-3 review-4-background"
                  style={{
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <h4
                    style={{ fontFamily: "Metropolis-bold" }}
                    className="mb-1 text-light"
                  >
                    Sanskar Maroo
                  </h4>
                  <div>
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                    <Icon
                      icon="ic:round-star-rate"
                      style={{ color: "#ffce31" }}
                      width={"20"}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Icon
                      type="button"
                      data-bs-toggle="modal"
                      data-bs-target="#review4Modal"
                      icon="uiw:plus-circle"
                      width="1.7em"
                      height="1.7em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          {/* review 1 modal */}
          <div
            class="modal fade"
            id="review1Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    I recently purchased the Emotorad X1 electric cycle, and it
                    has completely exceeded my expectations. From the sleek
                    design to the powerful performance, this e-bike is a
                    game-changer. The build quality is exceptional, providing a
                    sturdy and reliable feel while riding. The battery life is
                    impressive, allowing for extended rides without worrying
                    about running out of power. The pedal-assist feature is
                    seamless, making uphill climbs a breeze. The electric motor
                    delivers a smooth and responsive ride, and the overall
                    handling is superb. Whether you're a seasoned cyclist or new
                    to electric bikes, the Emotorad X1 offers a fantastic riding
                    experience for all. In conclusion, I highly recommend the
                    Emotorad X1 to anyone looking for a high-quality electric
                    cycle in affordable price. It combines style, performance,
                    and innovation seamlessly, making it a standout choice in
                    the market.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review 2 modal */}
          <div
            class="modal fade"
            id="review2Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    I recently purchased the EMotorad Doodle V2 e-cycle, and I
                    am thrilled with my experience! The bike's sleek design,
                    coupled with its powerful electric motor, provides a smooth
                    and enjoyable ride. The battery life exceeded my
                    expectations, allowing for longer rides without worrying
                    about running out of power. The intuitive controls and
                    comfortable seating make it a joy to navigate through
                    various terrains. EMotorad has truly created a fantastic
                    electric cycle that combines style, functionality, and
                    efficiency. I highly recommend the Doodle V2 to anyone
                    looking for a reliable and fun e-cycle.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review3 modal */}
          <div
            class="modal fade"
            id="review3Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    The E MOTORAD X2 is a remarkable electric bike that combines
                    impressive performance with exceptional comfort and
                    convenience. With its powerful motor and long-lasting
                    battery, the X2 delivers a smooth and efficient ride, making
                    it perfect for both daily commutes and weekend adventures.
                    The bike's sturdy frame and high-quality components ensure
                    durability and reliability, while its ergonomic design
                    provides a comfortable riding experience even on longer
                    journeys. The X2's compact size and lightweight construction
                    make it easy to manoeuvre in urban environments, while its
                    foldable design allows for convenient storage and
                    transportation. Overall, the E MOTORAD X2 is a fantastic
                    electric bike that offers excellent value for money, making
                    it a top choice for anyone looking for a reliable and
                    enjoyable mode of transportation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* review 4 modal */}
          <div
            class="modal fade"
            id="review4Modal"
            tabindex="-1"
            aria-labelledby="displayModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-dialog-centered">
              <div
                class="modal-content text-center text-light"
                style={{ backgroundColor: "#424242" }}
              >
                <div class="modal-body p-3">
                  <div className="d-flex justify-content-end mb-3">
                    <Icon
                      icon="zondicons:minus-solid"
                      width="1.2em"
                      type="button"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      height="1.2em"
                      style={{ color: "white", cursor: "pointer" }}
                    />
                  </div>
                  <p className="px-0 py-0 px-lg-3 py-lg-3 m-1">
                    I recently purchased an e-cycle, and I must say, it has
                    completely transformed my commuting experience. This
                    electric bike combines the convenience of a traditional
                    bicycle with the efficiency of an electric motor, offering a
                    truly versatile mode of transportation.First and foremost,
                    the e-cycle is incredibly eco-friendly. By opting for an
                    electric bike over a traditional vehicle, I'm significantly
                    reducing my carbon footprint and contributing to a cleaner
                    environment. It's empowering to know that my daily commute
                    is now emissions-free.One of the standout features of the
                    e-cycle is its electric motor. With just a simple push of a
                    button, I can effortlessly glide up hills and cover long
                    distances without breaking a sweat. The motor provides a
                    welcome boost, especially during those morning commutes when
                    I'm not quite awake yet.Moreover, the e-cycle is
                    surprisingly versatile. Whether I'm navigating busy city
                    streets or exploring scenic bike paths, it handles
                    beautifully in all environments. The sturdy frame and
                    responsive brakes instill confidence, while the adjustable
                    settings allow me to customize my riding experience to suit
                    my preferences.In terms of convenience, the e-cycle truly
                    shines. It's equipped with a removable battery that can be
                    easily charged at home or the office, ensuring that I always
                    have enough power for my journeys. Plus, the sleek design
                    and compact size make it easy to store in tight spaces,
                    whether it's in my apartment or a crowded bike rack.Overall,
                    I couldn't be happier with my e-cycle purchase. Not only has
                    it revolutionized my daily commute, but it's also helped me
                    lead a more sustainable lifestyle. If you're in the market
                    for an efficient and eco-friendly mode of transportation, I
                    highly recommend considering an e-cycle. It's truly a
                    game-changer.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FormPopUpModal
        gclid={gclid}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default CycleVsEcycle;
