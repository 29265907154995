export const bikeHighlights = [
  {
    name: "x1",
    meta: {
      title:
        "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
      description:
        "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1",
      range: {
        title:
          "Best Electric Bicycle for Men, Buy EMotorad X1 Electric Cycle at 16% Off!",
        description:
          "Buy the EMotorad X1, best electric cycle for men in India with 16% discount. Buy now for a seamless blend of speed and sustainability.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    warranty: "Up to 5 year warranty*",
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/81RlWux44HL._SL1600_.jpg",
          content:
            "I recently purchased this electric bicycle, and I'm thrilled with my experience. The pricing is a standout feature, especially when compared to other well-known commercial brands. The value for money is excellent, and I appreciate getting a quality electric bicycle without breaking the bank.",
          name: "Prashant Ar",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81YHcD86Z4L.jpg",
          content:
            "My son turned 15, and this seemed like the best gift to a teenager who just needs his first wings at independence. Simple as a bicycle, but just enough e-juice to climb hills and distances easily.",
          name: "Dwaipayan C",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/81JEKX7ipSL.jpg",
          content:
            "As a cyclist, I've always been intrigued by the idea of an electric bike, and my recent experience with the Emotorad E-Bike has been nothing short of exhilarating. Designed to seamlessly blend the best of both worlds - the efficiency of a conventional bicycle and the power of an electric motorOne of the most striking features of the Bike is its powerful yet silent motor.",
          name: "Naman Rawal",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.8", "700"],
    },
    links: {
      amazon: "https://amzn.eu/d/bljyJP3",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-blue-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm926585ecb845e?pid=ECYGRFT7FM3GKZWP",
    },
    bikeSpecs: {
      range: "Up to 35",
      speed: "Up to 25",
      battery: "7.65",
    },
    usp: "Thrifty, sturdy, and reliable",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Xfactor+(7+Sec+Product+Page)(1).mp4",
      tagline: "Endless Roads are the Limit.",
    },
    structure: {
      structureHeadline: "What Makes X1 Special?",
      structureDescription:
        "We believe in the power of simplicity in design. X1 is built for daily coffee runs, off-road endeavours, and everything in between.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/breakers/X1+Web+Banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/breakers/X1+Web+Banner+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/x1+red+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/X1+yellow+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/X1+blue+dimension.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use X1 for my office commute?",
        answer:
          "Yes, you can use the X1 electric cycle for your office commute. It’s designed with a single-speed freewheel, a powerful battery motor, and dual disc brakes, making it suitable for urban commuting.",
      },
      {
        question: "Can I use X1 on mountainous terrains?",
        answer:
          "The X1 electric cycle is designed to handle various terrains, including moderate off-road trails and mountainous terrain. Its versatility allows an enjoyable ride in mountainous regions and an exhilarating biking experience",
      },
      {
        question: "Can my kids ride X1?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding an X1 electric cycle. While it's designed for individuals over 12 years of age, children with sufficient cycling skills and appropriate supervision can safely enjoy X1. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "Is X1 electric cycle terrain specific?",
        answer:
          "No, X1 is an MTB electric cycle that is not terrain-specific. It is designed to handle various types of terrain due to its single-speed freewheel with an 18T ATA and dual disc brakes, which make cruising easy regardless of the terrain.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer:
          "X1 MTB Electric Cycle takes 3-4 hours to charge the battery from zero charge.",
      },
      {
        question: "How many gears does the X1 electric cycle have?",
        answer:
          "The X1 electric cycle features a single-speed freewheel with an 18T ATA, making cruising easy regardless of the terrain.",
      },
      {
        question: "Can I ride the X1 MTB electric cycle in rainy conditions?",
        answer:
          "X1 MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question: "How do I maintain the X1 MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the X1 MTB Electric Cycle in optimal condition. This includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional tune-ups are also recommended.",
      },
    ],
  },
  {
    name: "neo",
    meta: {
      title:
        "Buy Emotorad Neo, Best Electric Cycle Under 25K For Daily Commute [Upto 67% Off]",
      description:
        "Buy the EMotorad Neo Electric Cycle with 67% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/neo",
      range: {
        title: "Best Electric Cycles Under 30000 | Types of X Bicycles",
        description:
          "Discover the best electric cycles under 30000 with our comprehensive guide. Explore types of X bicycles perfect for your budget and lifestyle.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    warranty: "Up to 5 year warranty*",
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/81RlWux44HL._SL1600_.jpg",
          content:
            "I recently purchased this electric bicycle, and I'm thrilled with my experience. The pricing is a standout feature, especially when compared to other well-known commercial brands. The value for money is excellent, and I appreciate getting a quality electric bicycle without breaking the bank.",
          name: "Prashant Ar",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81YHcD86Z4L.jpg",
          content:
            "My son turned 15, and this seemed like the best gift to a teenager who just needs his first wings at independence. Simple as a bicycle, but just enough e-juice to climb hills and distances easily.",
          name: "Dwaipayan C",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/81JEKX7ipSL.jpg",
          content:
            "As a cyclist, I've always been intrigued by the idea of an electric bike, and my recent experience with the Emotorad E-Bike has been nothing short of exhilarating. Designed to seamlessly blend the best of both worlds - the efficiency of a conventional bicycle and the power of an electric motorOne of the most striking features of the Bike is its powerful yet silent motor.",
          name: "Naman Rawal",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.8", "700"],
    },
    links: {
      amazon:
        "https://www.amazon.in/MOTORAD-ELECTRIC-REVOLUTION-Removable-Suspension/dp/B0D5HPNB6F?ref_=ast_sto_dp",
      flipkart:
        "https://www.flipkart.com/emotorad-em-neo-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itmb5a1c5226b92f?pid=ECYHFCHZ25XMSDP3",
    },
    bikeSpecs: {
      range: "Up to 35",
      speed: "Up to 25",
      battery: "7.65",
    },
    usp: "Thrifty, sturdy, and reliable",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Xfactor+(7+Sec+Product+Page)(1).mp4",
      tagline: "Endless Roads are the Limit.",
    },
    structure: {
      structureHeadline: "What Makes Neo Special?",
      structureDescription:
        "We believe in the power of simplicity in design. Neo is built for daily coffee runs, off-road endeavours, and everything in between.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/breakers/breaker1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/breakers/breaker2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/neo/neoDimension.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use Neo for my office commute?",
        answer:
          "Yes, you can use the Neo electric cycle for your office commute. It’s designed with a single-speed freewheel, a powerful battery motor, and dual disc brakes, making it suitable for urban commuting.",
      },
      {
        question: "Can I use Neo on mountainous terrains?",
        answer:
          "The Neo electric cycle is designed to handle various terrains, including moderate off-road trails and mountainous terrain. Its versatility allows an enjoyable ride in mountainous regions and an exhilarating biking experience",
      },
      {
        question: "Can my kids ride Neo?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding the Neo electric cycle. While it's designed for individuals over 12 years of age, children with sufficient cycling skills and appropriate supervision can safely enjoy Neo. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "Is Neo electric cycle terrain specific?",
        answer:
          "No, Neo is an MTB electric cycle that is not terrain-specific. It is designed to handle various types of terrain due to its single-speed freewheel with an 18T ATA and dual disc brakes, which make cruising easy regardless of the terrain.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer: "The Neo battery typically takes 3-4 hours to fully charge.",
      },
      {
        question: "How many gears does the Neo electric cycle have?",
        answer:
          "The Neo electric cycle features a single-speed freewheel with an 18T ATA, making cruising easy regardless of the terrain.",
      },
      {
        question: "Can I ride the Neo MTB electric cycle in rainy conditions?",
        answer:
          "Neo MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question: "How do I maintain the Neo MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the Neo MTB Electric Cycle in optimal condition. This includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional tune-ups are also recommended.",
      },
    ],
  },
  {
    name: "x1-pro-legend-07",
    meta: {
      title:
        "Buy Legend 07 MSD Edition Electric Cycle, MS Dhoni Emotorad E Cycle @ 16% Off",
      description:
        "Buy the EMotorad Legend 07 MSD Edition Electric Cycle with 16% discount. Buy now for a seamless blend of speed and sustainability.",
      canonicalTag: "https://www.emotorad.com/bikes/x1-pro-legend-07",
      range: {
        title: "Best Electric Cycles Under 30000 | Types of X Bicycles",
        description:
          "Discover the best electric cycles under 30000 with our comprehensive guide. Explore types of X bicycles perfect for your budget and lifestyle.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    warranty: "Up to 5 year warranty*",
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/81RlWux44HL._SL1600_.jpg",
          content:
            "I recently purchased this electric bicycle, and I'm thrilled with my experience. The pricing is a standout feature, especially when compared to other well-known commercial brands. The value for money is excellent, and I appreciate getting a quality electric bicycle without breaking the bank.",
          name: "Prashant Ar",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81YHcD86Z4L.jpg",
          content:
            "My son turned 15, and this seemed like the best gift to a teenager who just needs his first wings at independence. Simple as a bicycle, but just enough e-juice to climb hills and distances easily.",
          name: "Dwaipayan C",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/81JEKX7ipSL.jpg",
          content:
            "As a cyclist, I've always been intrigued by the idea of an electric bike, and my recent experience with the Emotorad E-Bike has been nothing short of exhilarating. Designed to seamlessly blend the best of both worlds - the efficiency of a conventional bicycle and the power of an electric motorOne of the most striking features of the Bike is its powerful yet silent motor.",
          name: "Naman Rawal",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.8", "700"],
    },
    links: {
      amazon:
        "https://www.amazon.in/Legend-Single-Unisex-Electric-Yellow/dp/B0D7W51NVH",
      flipkart:
        "https://www.flipkart.com/emotorad-legend-07-black-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm01b6d7427271d?pid=ECYH2F4ZWHFKPGGH&lid=LSTECYH2F4ZWHFKPGGHJ1AICV&marketplace=FLIPKART&q=legend+07+msd+edition+emotorad",
    },
    bikeSpecs: {
      range: "Up to 35",
      speed: "Up to 25",
      battery: "7.65",
    },
    usp: "Limited Stock Available | MS Dhoni Edition",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Xfactor+(7+Sec+Product+Page)(1).mp4",
      tagline: "Endless Roads are the Limit.",
    },
    structure: {
      structureHeadline: "What Makes Legend 07 MSD Edition Special?",
      structureDescription:
        "As iconic as it sounds, the Legend 07 MSD Edition is a culmination of speed, style and comfort. Thrifty and nifty, it makes it ideal for long joyrides providing you the balance you need and are outdone only by it's acceleration and throttle effect. We present to you Dhoni's pick, the Legendary 07 Signature Edition.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/breakers/2000x606.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/breakers/2000x758+b.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/breakers/Msd+legend+edition+web+blue+banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/breakers/Msd+legend+edition+web+blue+banner+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/msd+blue+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/msd_yellow_dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/x1+legend+dimension+b.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/legend+07/x1+legend+dimension+w.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use Legend 07 MSD Edition for my office commute?",
        answer:
          "Yes, you can use the Legend 07 MSD Edition electric cycle for your office commute. It’s designed with a single-speed freewheel, a powerful battery motor, and dual disc brakes, making it suitable for urban commuting.",
      },
      {
        question: "Can I use Legend 07 MSD Edition on mountainous terrains?",
        answer:
          "The Legend 07 MSD Edition electric cycle is designed to handle various terrains, including moderate off-road trails and mountainous terrain. Its versatility allows an enjoyable ride in mountainous regions and an exhilarating biking experience",
      },
      {
        question: "Can my kids ride Legend 07 MSD Edition?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding the Legend 07 MSD Edition electric cycle. While it's designed for individuals over 12 years of age, children with sufficient cycling skills and appropriate supervision can safely enjoy Legend 07 MSD Edition. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "Is Legend 07 MSD Edition electric cycle terrain specific?",
        answer:
          "No, Legend 07 MSD Edition is an MTB electric cycle that is not terrain-specific. It is designed to handle various types of terrain due to its single-speed freewheel with an 18T ATA and dual disc brakes, which make cruising easy regardless of the terrain.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer:
          "The Legend 07 MSD Edition battery typically takes 3-4 hours to fully charge.",
      },
      {
        question:
          "How many gears does the Legend 07 MSD Edition electric cycle have?",
        answer:
          "The Legend 07 MSD Edition electric cycle features a single-speed freewheel with an 18T ATA, making cruising easy regardless of the terrain.",
      },
      {
        question:
          "Can I ride the Legend 07 MSD Edition MTB electric cycle in rainy conditions?",
        answer:
          "Legend 07 MSD Edition MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question:
          "How do I maintain the Legend 07 MSD Edition MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the Legend 07 MSD Edition MTB Electric Cycle in optimal condition. This includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional tune-ups are also recommended.",
      },
    ],
  },
  {
    name: "x1-limited-edition",
    meta: {
      title: "X1 Limited Edition | EMotorad X1 LM Electric Cycle",
      description:
        "Experience the future of cycling with the X1 Limited Edition Electric Cycle by EMotorad – a blend of style and sustainability in one package.",
      canonicalTag: "https://www.emotorad.com/bikes/x1-limited-edition",
      range: {
        title: "Best Electric Cycles Under 30000 | Types of X Bicycles",
        description:
          "Discover the best electric cycles under 30000 with our comprehensive guide. Explore types of X bicycles perfect for your budget and lifestyle.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "680"],
    },
    links: {
      amazon: "https://amzn.eu/d/50ZZnJl",
      flipkart:
        "https://www.flipkart.com/emotorad-x1-red-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm830863420d539?pid=ECYH28HFFWQTZWYW",
    },
    warranty: "Up to 5 year warranty*",
    bikeSpecs: {
      range: "Up to 35",
      speed: "Up to 25",
      battery: "7.65",
    },
    usp: "Thrifty and sturdy with 5 riding modes",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Xfactor+(7+Sec+Product+Page)(1).mp4",
      tagline: "Endless Roads are the Limit.",
    },
    structure: {
      structureHeadline: "What Makes X1 Limited Edition Special?",
      structureDescription:
        "The sturdy X1 frame combined with the power of an enhanced 7.65 AH battery, 5 levels of pedal assist, and LCD Display - this nifty e-cycle can go on mountains and glide on city roads.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/breakers/X1+Limited+edition+Web+Banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/breakers/X1+Limited+edition+Web+Banner+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/X1+limited+red+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/X1+limited+black+dimension.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x1-limited-edition/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "What’s the difference between X1 and X1 Limited Edition?",
        answer:
          "The X1 is as sturdily built as our beloved flagship X1 and features enhanced features like 5 levels of pedal assist and an LCD display. The limited edition frame is designed to be more user-friendly, making it suitable for heights from 5 feet to 6 feet.",
      },
      {
        question: "Can I use X1 Limited Edition for my office commute?",
        answer:
          "Yes, you can use the X1 Limited Edition electric cycle for your office commute. It’s designed with a single-speed freewheel, a powerful battery motor, and dual disc brakes, making it suitable for urban commuting.",
      },
      {
        question: "Can I use X1 Limited Edition on mountainous terrains?",
        answer:
          "The X1 Limited Edition electric cycle is designed to handle various terrains, including moderate off-road trails and mountainous terrain. Its versatility allows an enjoyable ride in mountainous regions and an exhilarating biking experience.",
      },
      {
        question: "Can my kids ride X1 Limited Edition?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding an X1 Limited Edition electric cycle. It is perfectly suitable for riders from 5 feet to 6 feet. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "Is X1 Limited Edition electric cycle terrain specific?",
        answer:
          "No, X1 Limited Edition is an MTB electric cycle that is not terrain-specific. It is designed to handle various types of terrain due to its single-speed freewheel with an 18T ATA and dual disc brakes, which make cruising easy regardless of the terrain.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer:
          "The X1 Limited Edition battery typically takes 3-4 hours to fully charge.",
      },
      {
        question:
          "How many gears does the X1 Limited Edition electric cycle have?",
        answer:
          "The X1 Limited Edition electric cycle features a single-speed freewheel with an 18T ATA, making cruising easy regardless of the terrain.",
      },
      {
        question:
          "Can I ride the X1 Limited Edition MTB electric cycle in rainy conditions?",
        answer:
          "X1 Limited Edition MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question:
          "How do I maintain the X1 Limited Edition MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the X1 Limited Edition MTB Electric Cycle in optimal condition. This includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional tune-ups are also recommended.",
      },
    ],
  },
  {
    name: "x2",
    meta: {
      title: "Electric Cycle Under 30000 in India, EMotorad X2 E-Cycle",
      description:
        "Best electric cycle under ₹30,000? Experience the Best of Electric Biking with the EMotorad X2. Top Features, Exceptional Performance, All Under ₹30,000.",
      canonicalTag: "https://www.emotorad.com/bikes/x2",
      range: {
        title: "Best Electric Cycles Under 30000 | Types of X Bicycles",
        description:
          "Discover the best electric cycles under 30000 with our comprehensive guide. Explore types of X bicycles perfect for your budget and lifestyle.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/81ld6vxyEYL._SL1600_.jpg",
          content:
            "The build quality of the cycle is great. Riding experience is pretty good. It looks awesome. Tires look pretty good and make me feel safe. Easy to assemble but guidance paper would help more.",
          name: "Taj Ahmed",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/71sOvWJh+TL._SL1600_.jpg",
          content:
            "Delighted with my Emotarad E cycle purchase! Perfect for Himachal's scenic hills, my 12-year-old loves the thrill on those roads. The Diwali offer made it even sweeter, and now my cousin is getting one too after trying it out. Thanks, EMotard, for delivering such quality at a great price!",
          name: "Roshan",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/71Br5F0C2sL._SL1600_.jpg",
          content:
            "My kid loves it and is making most of it.. it's paddle assist is the best feature as it maintains the cycling aspects and provides that perfect assistance to make the rider going on and on. Loved it and thank you emotorad for making this wonderful product.",
          name: "Sachit S.",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.3", "496"],
    },
    links: {
      amazon: "https://amzn.eu/d/cbu0Lx8",
      flipkart:
        "https://www.flipkart.com/emotorad-x2-red-27-5-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itm54ac4c226788b",
    },
    warranty: "Up to 5 year warranty*",
    bikeSpecs: {
      range: "Up to 35",
      speed: "Up to 25",
      battery: "7.65",
    },
    usp: "High tensile step-through steel frame",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Xfactor+(7+Sec+Product+Page)(1).mp4",
      tagline: "Endless Roads are the Limit.",
    },
    structure: {
      structureHeadline: "What Makes X2 Special?",
      structureDescription:
        "The step-through high-tensile steel frame makes it highly versatile for both men and women. The compact display and a powerful motor ensure you stay one step ahead at every turn!",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/breakers/X2+Web+banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/breakers/X2+Web+banner+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/X2+Red+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/X2+blue+dimension.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x2/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use X2 for my office commute?",
        answer:
          "Yes! The X2 electric cycle is perfect for your daily office commute. With its single-speed freewheel, powerful battery motor, and dual disc brakes, it's specifically designed to navigate city streets with ease.",
      },
      {
        question: "Can I use X2 on mountainous terrains?",
        answer:
          "The X2 electric cycle is built for a range of terrains, from moderate off-road trails to challenging mountain paths. Its versatility ensures a smooth and enjoyable ride through mountainous regions, offering an exhilarating biking experience for weekend adventurers.",
      },
      {
        question: "Can my kids ride X2?",
        answer:
          "Yes! Depending on your child's age, height, and cycling experience, they may enjoy riding an X2 electric cycle. While it's designed for individuals over 12 years of age, children with sufficient cycling skills and appropriate supervision can safely enjoy X2. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "Is X2 electric cycle terrain specific?",
        answer:
          "No, X2 is an MTB electric cycle that is not terrain-specific. It is designed to handle various types of terrain due to its single-speed freewheel with an 18T ATA and dual disc brakes, which make cruising easy regardless of the terrain.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer: "The X2 battery typically takes 3-4 hours to fully charge.",
      },
      {
        question: "How many gears does the X2 electric cycle have?",
        answer:
          "The X2 electric cycle features a single-speed freewheel with an 18T ATA, making it perfect for everyday commuting to weekend offroading.",
      },
      {
        question: "Can I ride the X2 MTB electric cycle in rainy conditions?",
        answer:
          "X2 MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question: "How do I maintain the X2 MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the X2 MTB Electric Cycle in optimal condition. This involves cleaning and lubricating the chain, monitoring tyre pressure, examining brakes, and verifying the functionality of all components. Periodic professional tune-ups can also help keep your e-cycle in peak performance.",
      },
      {
        question: "Is the X2 suitable for both men and women?",
        answer:
          "Yes, the X2 electric cycle is designed to be unisex, featuring a step-through high tensile steel frame that makes it easy for riders of all genders to mount and dismount the bike comfortably.",
      },
      {
        question: "Is X2 lightweight?",
        answer:
          "The X2 is designed to be lightweight, making it easy to handle and manoeuvre. Whether you're commuting in the city or exploring the off-road trails, no corners will scare you with the complete control you will have.",
      },
    ],
  },
  {
    name: "x3",
    meta: {
      title: "X3 Electric Cycle | Buy EMotorad X3 With 40+ Range",
      description:
        "Revolutionize Your Ride with the X3 Electric Cycle – Combining Style, Speed, and a Remarkable 40+ KM Range",
      canonicalTag: "https://www.emotorad.com/bikes/x3",
      range: {
        title: "Best Electric Cycles Under 30000 | Types of X Bicycles",
        description:
          "Discover the best electric cycles under 30000 with our comprehensive guide. Explore types of X bicycles perfect for your budget and lifestyle.",
        canonicalTag: "https://www.emotorad.com/bikes/range/x-factor",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/71NA+fr-veL._SL1600_.jpg",
          content:
            "I bought X3 because I wanted to give e-bikes a try. It is truly a great way to keep yourself fit and it looks amazing. I take it with me to work as well because it has throttle mode and it’s speedier compared to normal bicycles. ",
          name: "Rutuja Ghayal",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81JGJHx1TXL._SL1600_.jpg",
          content:
            "Having learned about both the company's product and its customer service, my experience aligns with the positive reputation. I am genuinely satisfied with the product and the Emotorad team. While I had considered exploring new brands, I heard various complaints about their products, which led me to choose Emotorad.",
          name: "Vicky Singh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/81t5fxmDHZL._SL1600_.jpg",
          content:
            "The bicycle looks is amazing. For its inbuilt Batteries no one identified that this is electric cycle or normal cycle. The range of the cycle also quite nice.",
          name: "Mustansir Ali",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.1", "44"],
    },
    links: {
      amazon: "https://amzn.eu/d/4t5o3tC",
      flipkart:
        "https://www.flipkart.com/emotorad-x3-gold-700c-inches-single-speed-lithium-ion-li-ion-electric-cycle/p/itmab57814715fe8",
    },
    warranty: "Up to 5 year warranty*",
    bikeSpecs: {
      range: "Up to 40",
      speed: "Up to 25",
      battery: "7.0",
    },
    usp: "Integrated Battery",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Xfactor+(7+Sec+Product+Page)(1).mp4",
      tagline: "Endless Roads are the Limit. ",
    },
    structure: {
      structureHeadline: "What Makes X3 Special?",
      structureDescription:
        "The integrated battery coupled with a high-performance rear hub motor makes it a perfect commute partner at every turn.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/breakers/X3+Web+banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/breakers/X3+Web+banner+2.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/breakers/X3+Blak+Lifestyle+image+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/breakers/X3+Blak+Lifestyle+image+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/X3+Grey+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/X3+Ssilver+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/x3+black.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/x3/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question:
          "Is the battery of the X3 electric cycle integrated into the frame?",
        answer:
          "Yes, the battery of the X3 electric cycle is seamlessly integrated into the frame for a sleek and modern look.",
      },
      {
        question:
          "What are the benefits of having an integrated battery in X3?",
        answer:
          "It provides a sleek and modern design, improving the e-cycle's handling and balance. It also protects the battery from external elements and allows for more design flexibility and easier handling. Smoother rides? You bet it is.",
      },
      {
        question: "Can I use X3 for my office commute?",
        answer:
          "Certainly! The X3 electric cycle is perfect for your daily office commute. With its single-speed freewheel, powerful battery motor, and dual disc brakes, it's specifically designed to navigate urban environments with ease.",
      },
      {
        question: "Can I use X3 on mountainous terrains?",
        answer:
          "The X3 electric cycle is built for a range of terrains, from moderate off-road trails to challenging mountain paths. Its versatility ensures a smooth and enjoyable ride through mountainous regions, offering an exhilarating biking experience for adventure seekers.",
      },
      {
        question: "Can my kids ride X3?",
        answer:
          "Yes! Depending on your child's age, height, and cycling experience, they may enjoy riding an X3 electric cycle. While it's designed for individuals over 12 years of age, children with sufficient cycling skills and appropriate supervision can safely enjoy X3. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "Is X3 electric cycle terrain specific?",
        answer:
          "No, X3 is an MTB electric cycle that is not terrain-specific. It is designed to handle various types of terrain due to its single-speed freewheel with an 18T ATA and dual disc brakes, which make cruising easy regardless of the terrain.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer: "The X3 battery typically takes 3-4 hours to fully charge.",
      },
      {
        question: "How many gears does the X3 electric cycle have?",
        answer:
          "The X3 electric cycle features a single-speed freewheel with an 18T ATA, making cruising easy regardless of the terrain.",
      },
      {
        question: "Can I ride the X3 MTB electric cycle in rainy conditions?",
        answer:
          "X3 MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question: "How do I maintain the X3 MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the X3 MTB Electric Cycle in optimal condition. This involves cleaning and lubricating the chain, monitoring tyre pressure, examining brakes, and verifying the functionality of all components. Periodic professional tune-ups can also help keep your e-cycle in peak performance.",
      },
    ],
  },
  {
    name: "t-rex+",
    meta: {
      title: "Best Electric Cycle Under 45000 | EMotorad T-Rex+",
      description:
        "Looking for the best electric cycle under 45000? The EMotorad T-Rex+ is your answer. Experience eco-friendly commuting without compromising on quality.",
      canonicalTag: "https://www.emotorad.com/bikes/t-rex+",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "149"],
    },
    links: {
      amazon:
        "https://www.amazon.in/dp/B0DD465JPV/ref=sr_1_6?crid=1IW6H9G8BJ8U2&dib=eyJ2IjoiMSJ9.cYDQsnVD0XEwsYex7ZTi1UnewZp941uy6PM73p_FZlEpudQqazk1kSnLyVP50nb-sV2s4aezosCl_Ct0s2jmNkt8TN02Qt9sXTbI_x8mAOPRr6zewIkvB0x2MFJRmSUf8abPUxjj2Zzx8pMuO8ig138QGED-UmssxkbG2zVgxp7HwupsHK-9qTI9sTeuNcrffc5cZt0aoEyWGXe3vGwjhUPsuYmOuhQJWNflb4lgq8s.MkaMSugnmkhKTR5JiBY4Eai0iR1myPHuCJgL3kesTDY&dib_tag=se&keywords=trex%2B+cycle&qid=1723804703&sprefix=trex%2B+cyc%2Caps%2C216&sr=8-6",
      flipkart: "https://www.emotorad.com/bikes/t-rex+",
    },
    warranty: "Lifetime Warranty*",
    bikeSpecs: {
      range: "Up to 50",
      speed: "Up to 25",
      battery: "10.2",
    },
    usp: "18.5” Aluminium Alloy 6061 Frame",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Trex%2B+website+video+.mp4",
      tagline: "Limitless paths, endless adventures.",
    },
    structure: {
      structureHeadline: "What makes T-Rex+ Special",
      structureDescription:
        "The combination of power, comfort, and style makes for a one-of-a-kind ride. With TRex+, every journey is an adventure, and every ride is a chance for a truly unique e-cycling experience. Whether you're commuting or simply cruising through your city, you'll love the thrill of the ride.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex%2B+v3/breaker+images/breaker1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex%2B+v3/breaker+images/breaker2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex%2B+v3/T-REX+%2B+new+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex%2B+v3/T-REX+%2B+olive+dimension.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use TRex+ for my office commute?",
        answer:
          "Yes, the TRex+ is suitable for office commutes and daily errands. It provides a convenient and eco-friendly mode of transportation.",
      },
      {
        question: "How is a stem-integrated display different?",
        answer:
          "A stem-integrated display is a unique feature of the T-Rex+, where the display is embedded in the stem of the cycle. It shows essential information like speed, battery level, and mode selection through a sleek and minimalist design.",
      },
      {
        question: "Can I use T-Rex+ on mountainous terrains?",
        answer:
          "Yes, the TRex+ is designed to easily tackle various terrains, including off-road trails and mountainous terrain. Its robust build and advanced features make it an ideal companion for adventurous rides in hilly and mountainous regions.",
      },
      {
        question: "Can my kids ride T-Rex+?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding the TRex+ electric cycle. While it's designed for individuals over 18, children with sufficient cycling skills and appropriate supervision can safely enjoy the TRex+. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride. It's essential to assess their abilities and provide guidance to ensure a fun and secure riding experience.",
      },
      {
        question: "How many gears does the T-Rex+ cycle have?",
        answer:
          "The TRex+ typically comes with a 7 Shimano Altus drivetrain, allowing you to tackle every terrain easily.",
      },
      {
        question: "Can I ride the T-Rex+ electric cycle in rainy conditions?",
        answer:
          "The TRex+ is designed to withstand light rain and wet conditions, making it suitable for riding in moderate rain. However, submerging the e-cycle or riding in heavy rain, flooding, or deep water is not recommended, as this may damage electrical components and compromise safety. If you need to ride in rainy conditions, please exercise caution and avoid excessive water exposure to ensure your safety and the longevity of the e-cycle.",
      },
      {
        question: "How do I maintain the T-Rex+ electric cycle?",
        answer:
          "Regular maintenance includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional tune-ups are also recommended for optimal performance.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer: "The T-Rex+ battery typically takes 4-5 hours to fully charge.",
      },
    ],
  },
  {
    name: "t-rex-air",
    meta: {
      title: "Best Electric Cycle Under 40000 in India, EMotorad T-Rex Air",
      description:
        "Looking for the best electric cycle under 40000? The EMotorad T-Rex Air is your answer. Experience eco-friendly commuting without compromising on quality.",
      canonicalTag: "https://www.emotorad.com/bikes/t-rex-air",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "149"],
    },
    links: {
      amazon: "https://amzn.eu/d/h7E30qr",
      flipkart:
        "https://www.flipkart.com/emotorad-t-rex-air-red-29-inches-7-gear-lithium-ion-li-ion-electric-cycle/p/itma21f63ee25156",
    },
    warranty: "Up to 5 year warranty*",
    bikeSpecs: {
      range: "Up to 50",
      speed: "Up to 25",
      battery: "10.2",
    },
    usp: '29" Wheel Size',

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Trex+(Product+Page+7+Sec).mp4",
      tagline: "A Beast on Two Wheels.",
    },
    structure: {
      structureHeadline: "What Makes T-Rex Air Special?",
      structureDescription:
        "Whether you want to avoid the traffic or get through the difficult terrains, T-Rex Air with its 5 pedal assist mode does everything, the high-performance 250W rear hub motor makes every ride feel like a smooth rollercoaster of fun.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/breakers/Trex+air+Web+Banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/breakers/Trex+air+Web+Banner+2+copy.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/t-rex-air-dimension.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/T+rex+air++yellow+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/t-rex-air-dimension+orange.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/t-rex-air-dimension+green.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/t-rex-air-dimension-forest.png",
    ],

    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex-air/gallery/Artboard+4.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use T-Rex Air for my office commute?",
        answer:
          "Yes, you can use the T-Rex Air electric cycle for your office commute. It’s designed with a seven-speed freewheel, a powerful battery motor, and dual disc brakes, making it suitable for urban commuting and weekend offroading.",
      },
      {
        question: "Can I use T-Rex Air on mountainous terrains?",
        answer:
          "The T-Rex Air electric cycle is designed to handle various terrains, including off-road trails and mountainous terrain. Its versatility allows an enjoyable ride in mountainous regions and an exhilarating biking experience.",
      },
      {
        question: "Can my kids ride T-Rex Air?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding an T-Rex Air electric cycle. While it's designed for individuals over 12 years of age, children with sufficient cycling skills and appropriate supervision can safely enjoy T-Rex Air. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer:
          "The T-Rex Air battery typically takes 4-5 hours to fully charge.",
      },
      {
        question:
          "Can I ride the T-Rex Air MTB electric cycle in rainy conditions?",
        answer:
          "T-Rex Air MTB Electric Cycle is designed to withstand light rain and wet conditions. However, submerging the ecycle or riding in heavy rain or flooding is not recommended as it may damage electrical components and compromise safety.",
      },
      {
        question: "How do I maintain the T-Rex Air MTB electric cycle?",
        answer:
          "Regular maintenance is essential to keeping the T-Rex Air MTB Electric Cycle in optimal condition. This includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional tune-ups are also recommended.",
      },
    ],
  },
  {
    name: "emx",
    meta: {
      title:
        "Best Mountain Electric Cycle in India, Buy EMotorad EMX at Best Price",
      description:
        "Indulge in the ultimate electric cycling adventure with the EMX Mountain Electric Cycle from EMotorad. Shop now for the best prices on this high-performance bike",
      canonicalTag: "https://www.emotorad.com/bikes/emx",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.4", "115"],
    },
    links: {
      amazon: "https://amzn.eu/d/ak5wfid",
      flipkart:
        "https://www.flipkart.com/emotorad-emx-aqua-27-5-inches-21-gear-lithium-ion-li-ion-electric-cycle/p/itm692a1aaa52ee1?pid=ECYGTXQZCNEVDFBT",
    },
    warranty: "Lifetime warranty*",
    bikeSpecs: {
      range: "50+",
      speed: "Up to 25",
      battery: "10.4",
    },
    usp: "Dual suspension",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Emx+(7+Sec+Product+Page).mp4",
      tagline: "A beast on two wheels.",
    },
    structure: {
      structureHeadline: "What makes EMX Special",
      structureDescription:
        "Ignore the bumps with the comfort of dual suspension and take it to the mountains because it got a powerful 250W motor. Ride it. Believe it. Live it.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/breakers/Artboard+1+(1)+(1).jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/breakers/Artboard+4+(2).jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/EMX+dimension.png",
    ],

    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use EMX for everyday commuting?",
        answer:
          "Yes! EMX electric cycle is great for not just your regular commute, but also for occasional off-roading. Its dual suspension setup and a powerful motor makes it perfect for almost any terrain you throw at it. It’s not just fun, it’s thrilling!",
      },
      {
        question: "Can I use EMX on Mountains?",
        answer:
          "EMX is built to cruise through all kinds of terrain, including mountains. With its dual suspension system, multiple gears, and a high-performance motor, it'll give you a thrilling ride on any terrain you take it to.",
      },
      {
        question: "Can my kids ride EMX?",
        answer:
          "For sure! If your kids are old enough and know how to ride safely, they can enjoy EMX too. Just ensure they're supervised and comfortable with the e-cycle before hitting the road.",
      },
      {
        question: "Is EMX terrain specific?",
        answer:
          "What makes it standout is its versatility factor. EMX is designed to handle any kind of terrain you throw at it. Whether you're riding in the city, your backyard dirt trails, or rocky paths, it can handle it all.",
      },
      {
        question: "How many gears does EMX have?",
        answer:
          "EMX packs a whopping 21 gears! 3 at the front and 7 at the rear. So you've got plenty of options for cruising through the town or climbing up those steep hills, it goes smooth like butter.",
      },
      {
        question: "Can I ride EMX in rainy conditions?",
        answer:
          "You can ride EMX in light rain and wet conditions, but it's best to avoid heavy rain or deep water. Too much water can damage the e-cycle’s electrical parts.",
      },
      {
        question: "How do I maintain EMX?",
        answer:
          "To keep your EMX in top shape, make sure to clean and oil the chain regularly, check the tyre pressure, and inspect the brakes. Always stay on top of regular maintenance checks to keep it running smoothly.",
      },
    ],
  },
  {
    name: "emx+",
    meta: {
      title:
        "Best Long Range Electric Cycle in India 2024, EMotorad EMX+ With 80KM Range",
      description:
        "Indulge in the ultimate electric cycling adventure with the EMX+ Electric Cycle from EMotorad. Shop now for the best prices on this high-performance, long range E bike!",
      canonicalTag: "https://www.emotorad.com/bikes/emx+",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/614KJL-BFRL._SL1600_.jpg",
          content:
            "The Emotorad EMX ebike is an outstanding choice for anyone in the market for an electric bike. The bike is well-built, with a sturdy frame and comfortable seat that makes for a smooth ride. The motor is powerful and provides plenty of assistance when needed, making it easy to tackle hills and headwinds.",
          name: "	Devajit D",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/71Y5FgFVjFL._SL1600_.jpg",
          content:
            "I did see some reviews with issues and I was a little reserved while purchasing it but I'm happy to say that I have not faced any issues with the bike till now.",
          name: "Vignesh N",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/81qwJxVtPxL.jpg",
          content:
            "Riding the EMOTORAD EMX+ electric bicycle was a transformative experience, seamlessly blending cutting-edge technology with exhilarating performance. From the moment I straddled the sleek frame, I felt a surge of excitement coursing through me.",
          name: "	Mr. Vadhera",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.5", "149"],
    },
    links: {
      amazon: "https://amzn.eu/d/ak5wfid",
      flipkart:
        "https://www.flipkart.com/emotorad-emx-aqua-27-5-inches-21-gear-lithium-ion-li-ion-electric-cycle/p/itm692a1aaa52ee1?pid=ECYGTXQZCNEVDFBT",
    },
    warranty: "Lifetime warranty*",
    bikeSpecs: {
      range: "Up to 80",
      speed: "Up to 25",
      battery: "13",
    },
    usp: "Dual suspension",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Emx+(7+Sec+Product+Page).mp4",
      tagline: "A Beast on Two Wheels.",
    },
    structure: {
      structureHeadline: "What Makes EMX+ Special?",
      structureDescription:
        "Double the adventures with double suspension that EMX+ carries, extending your cruising experience through its 5 modes and 13Ah battery. Ride it. Believe it. Live it.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/breakers/EMX+Web+Banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/breakers/EMX+Web+Banner+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/EMX+%2B+Aqua+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/EMX%2B_WhiteRed_dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/EMX+%2B+Neon+dimension.png",
    ],

    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/gallery/Artboard+3.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/emx+plus/gallery/Artboard+5.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use EMX+ for my office commute?",
        answer:
          "Absolutely! EMX+ electric cycle is great for getting to work. It's got a comfy full suspension, handy gears, and a powerful motor, making it perfect for city streets. Plus, it's got safety features like auto cut off brakes.",
      },
      {
        question: "Can I use EMX+ on mountains?",
        answer:
          "You bet! EMX+ is built to tackle all kinds of terrain, including mountains. With its suspension system, multiple gears, and strong motor, it'll give you a thrilling ride on those mountain trails.",
      },
      {
        question: "Can my kids ride EMX+?",
        answer:
          "For sure! If your kids are old enough and know how to ride safely, they can enjoy EMX+ too. Just ensure they're supervised and comfortable with the ecycle before hitting the road.",
      },
      {
        question: "Is EMX+ terrain specific?",
        answer:
          "Nope, not at all! EMX+ is designed to handle any kind of terrain. Whether you're riding on pavement, dirt trails, or rocky paths, this ecycle can handle it all.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer:
          "The EMX+ battery typically takes 4.6 hours to charge 80% and 5-7 hours to fully charge.",
      },
      {
        question: "How many gears does EMX+ have?",
        answer:
          "EMX+ has a whopping 21 gears! So you've got plenty of options for cruising around town or climbing up those steep hills.",
      },
      {
        question: "Can I ride EMX+ in rainy conditions?",
        answer:
          "You can ride EMX+ in light rain and wet conditions, but it's best to avoid heavy rain or deep water. Too much water can damage the ecycle’s electrical parts and make your ride less safe.",
      },
      {
        question: "How do I maintain EMX+?",
        answer:
          "To keep your EMX+ in top shape, make sure to clean and oil the chain regularly, check the tyre pressure, and inspect the brakes. It's also a good idea to get it tuned up by a professional every so often to keep it running smoothly.",
      },
    ],
  },
  {
    name: "doodle-v2",
    meta: {
      title: "Doodle V2 | Buy Best Doodle Electric Cycle at Best Price",
      description:
        "Buy the best doodle elctric bicylce at best price from Emotorad. Buy now and join the e-bike revolution with Doodle V2.",
      canonicalTag: "https://www.emotorad.com/bikes/doodle-v2",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.4", "75"],
    },
    links: {
      amazon: "https://amzn.eu/d/0LcPn9I",
      flipkart:
        "https://www.flipkart.com/emotorad-doodle-v3-20-inches-7-gear-lithium-ion-li-ion-electric-cycle/p/itm409758f005870?pid=ECYHFQT5QMDYTMPM",
    },
    warranty: "Lifetime warranty*",
    bikeSpecs: {
      range: "35+",
      speed: "Up to 25",
      battery: "10.4",
    },
    usp: "Foldable fat tyre SUV of e-cycles",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Doodle(Product+Page+7+Sec).mp4",
      tagline: "A beast on two wheels.",
    },
    structure: {
      structureHeadline: "What makes Doodle Special",
      structureDescription:
        "It folds to fit your lifestyle and unfolds to meet your adventure needs with a 10.4 Ah removable battery and  5 Pedal Assist Modes. Ride it. Believe it. Live it.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/breakers/Artboard+2.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/breakers/Artboard+3.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/doodle+V2+dimension.png",
    ],

    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Are fat tyres good for all-terrain?",
        answer:
          "Yes, fat tyres like the ones on Doodle V2 are great for various terrains. They provide better traction and stability, making them suitable for everything from urban jungles to actual ones. Unleash the beast!",
      },
      {
        question: "Can I easily fold Doodle V2 for storage?",
        answer:
          "Yes, you can easily fold Doodle V2 for storage. Its foldable aluminium alloy frame allows you to compactly fold the e-cycle, making it convenient to store in small spaces such as closets, car trunks, or under desks.",
      },
      {
        question: "Can I use Doodle V2 for commuting?",
        answer:
          "Yes, you can! The Doodle V2 electric cycle is perfect for getting to work or getting done with your everyday errands. It folds up easily and you can cruise through any terrain, thanks to its powerful motor. A fun ride? You bet it is!",
      },
      {
        question: "Can I take Doodle V2 on mountain trails?",
        answer:
          "Absolutely! Doodle V2 is built to handle all kinds of terrain, including mountains. It has special features like a front fork with suspension and multiple gears to make your mountain rides fun and comfortable.",
      },
      {
        question: "Can my kids ride Doodle V2?",
        answer:
          "Yes, they can! As long as your kids are old enough and know how to ride safely, they can enjoy Doodle V2 too. Just make sure they're supervised and comfortable with the e-cycle before they ride.",
      },
      {
        question: "How many gears does Doodle V2 have?",
        answer:
          "Doodle V2 has 7 gears so that you can choose the right one for your ride. Whether you're going uphill or cruising on flat ground, you'll find the perfect gear to keep you moving.",
      },
      {
        question: "Can I ride Doodle V2 in the rain?",
        answer:
          "You can ride Doodle V2 in light rain and wet conditions, but it's best to avoid heavy rain or deep water. Too much water can damage its electrical parts.",
      },
      {
        question: "How do I take care of my Doodle V2?",
        answer:
          "To keep your Doodle V2 in top shape, make sure to clean and oil the chain regularly, check the tyre pressure, and inspect the brakes. Make sure to stay on top of regular maintenance checks to keep running smoothly.",
      },
    ],
  },
  {
    name: "doodle-v3",
    h1: "Doodle V3 Foldable E Cycle",
    meta: {
      title: "Foldable Electric Cycle in India, Buy EMotorad Doodle V3",
      description:
        " Doodle V3, EMotorad's latest foldable electric cycle, blending convenience and innovation. Purchase now for a compact, eco-friendly travel companion",
      canonicalTag: "https://www.emotorad.com/bikes/doodle-v3",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/7133jU8bNmL._SL1600_.jpg",
          content:
            "Neat, Easily installed, compact enough to fit in the back of my car, stylish, useful. Yet to figure the range others but overall a nice product for solo transport.",
          name: "Sheena A",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/91-GTtgFJ7L._SL1600_.jpg",
          content:
            "Love the design and build quality.. good posture and performance. Easy to assemble. Good service from the company.",
          name: "Anush",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/81TAy7GoV7L.jpg",
          content: "The best one for Bengaluru traffic, awesome product",
          name: "Jones",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.4", "98"],
    },
    links: {
      amazon:
        "https://www.amazon.in/EMotorad-Fat-Tyre-Foldable-Mountain-Removable/dp/B0D6N39DJM/",
      flipkart:
        "https://www.flipkart.com/emotorad-doodle-v3-20-inches-7-gear-lithium-ion-li-ion-electric-cycle/p/itm9b7ead2cb84c6",
    },
    warranty: "Lifetime warranty*",
    bikeSpecs: {
      range: "Up to 60",
      speed: "Up to 25",
      battery: "12.75",
    },
    usp: "Cluster C6 Display",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Doodle(Product+Page+7+Sec).mp4",
      tagline: "A Beast on Two Wheels.",
    },
    structure: {
      structureHeadline: "What Makes Doodle V3 Special?",
      structureDescription:
        "A foldable fat-tyred e-cycle with an incredibly long-lasting battery support. From your room, to roads and railways, you can take this beast anywhere.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/breakers/Doodle+Web+Banner+1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/breakers/Doodle+Web+Banner+2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/doodle+V3+noc+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/doodle+V3+black+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle/doodle+V2+dimension.png",
    ],

    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/gallery/Artboard+7.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/gallery/Artboard+8.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/gallery/Artboard+9.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/gallery/Artboard+10.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/gallery/Artboard+11.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle+v3/gallery/Artboard+12.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Are fat tyres good for all-terrain?",
        answer:
          "Yes, fat tyres like the ones on Doodle V3 electric cycle are great for various terrains. They provide better traction and stability, making them suitable for urban streets, moderate off-road trails, hilly terrain, and even some mountainous regions. So, fat tyres are indeed good for all-terrain riding.",
      },
      {
        question: "Can I easily fold Doodle V3 electric cycle for storage?",
        answer:
          "Yes, you can easily fold Doodle V3 electric cycle for storage. Its foldable aluminum alloy frame allows you to compactly fold the ecycle, making it convenient to store in small spaces such as closets, car trunks, or under desks.",
      },
      {
        question: "Can I use Doodle V3 to commute to work?",
        answer:
          "Yes, you can! Doodle V3 electric cycle is perfect for getting to work. It folds up, so it's easy to store in small spaces. Plus, it has gears to help you tackle hills and a powerful motor to make your ride smooth and safe.",
      },
      {
        question: "Can I take Doodle V3 on mountain trails?",
        answer:
          "Absolutely! Doodle V3 is built to handle all kinds of terrain, including mountains. It has special features like a front fork with suspension and multiple gears to make your mountain rides fun and comfortable.",
      },
      {
        question: "Can my kids ride Doodle V3?",
        answer:
          "Yes, they can! As long as your kids are old enough and know how to ride safely, they can enjoy Doodle V3 too. Just make sure they're supervised and comfortable with the ecycle before they ride.",
      },
      {
        question: "Is Doodle V3 only good for certain types of terrain?",
        answer:
          "Nope! Doodle V3 is versatile and can handle any terrain you throw at it. Whether you're riding on pavement or off-road trails, this electric cycle can handle it all.",
      },
      {
        question: "How long does it take to charge Doodle V3's battery?",
        answer:
          "The Doodle V3 battery typically takes 4.6 hours to charge 80% and 5-7 hours to fully charge.",
      },
      {
        question: "How many gears does Doodle V3 have?",
        answer:
          "Doodle V3 has 7 gears so that you can choose the right one for your ride. Whether you're going uphill or cruising on flat ground, you'll find the perfect gear to keep you moving.",
      },
      {
        question: "Can I ride Doodle V3 in the rain?",
        answer:
          "You can ride Doodle V3 in light rain and wet conditions, but it's best to avoid heavy rain or deep water. Too much water can damage its electrical parts and make your ride less safe.",
      },
      {
        question: "How do I take care of my Doodle V3?",
        answer:
          "To keep your Doodle V3 in top shape, make sure to clean and oil the chain regularly, check the tyre pressure, and inspect the brakes. It's also a good idea to get it tuned up by a professional every so often to keep it running smoothly.",
      },
    ],
  },
  {
    name: "lile",
    meta: {
      title: "Best Electric Kick Scooter in India, EMotorad Lil E Kick Scooter",
      description:
        "Discover the Lil E Kick Scooter, an affordable electric scooter under ₹30,000. Designed for efficiency and ease, it's the perfect choice for budget-conscious riders.",
      canonicalTag: "https://www.emotorad.com/bikes/lile",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "27"],
    },
    links: {
      amazon: " https://amzn.in/d/18NqGJL",
      flipkart: "https://www.emotorad.com/bikes/lile",
    },
    warranty: "Up to 1 year warranty*",
    bikeSpecs: {
      range: "Up to 15",
      speed: "Up to 25",
      battery: "5.2",
    },
    usp: "Foldable kick scooter",
    structure: {
      structureHeadline: "What makes Lil E Special",
      structureDescription:
        "Get ready for thrilling adventures with the Lil E Electric Kick Scooter! Designed for teens, it delivers a smooth, exciting ride every time. Light and easy to handle, Lil E is always up for action and never runs out of fun. It’s the ultimate ride for your next adventure!",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/LilENew/Lil+E+banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/LilENew/Lil+E+banner2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/LilENew/Lile+blue+dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/LilENew/Lile+red+dimension.png",
    ],
    faq: [
      [
        {
          question: "What is the weight limit for riders?",
          answer:
            "The bike is designed to support riders up to 100 kg, ensuring a safe and comfortable ride.",
        },
        {
          question: "How long does it take to charge the battery?",
          answer:
            "The 5.2Ah lithium-ion battery takes approximately 4-5 hours to fully charge.",
        },
        {
          question: "Can I ride Lil E in the rain?",
          answer:
            "While the kick scooter is designed for various weather conditions, it is recommended to avoid heavy rain to protect electrical components.",
        },
        {
          question: "What is the weight of Lil E?",
          answer:
            "The lightweight aluminium alloy 6061 frame keeps the kick scooter’s weight around 12 kg, making it easy to carry and store.",
        },
        {
          question: "Is the Lil E kick scooter suitable for uphill rides?",
          answer:
            "Yes, the 250W motor provides sufficient power for tackling moderate inclines with ease.",
        },
        {
          question: "What is the range of Lil E on a single charge?",
          answer:
            "You can enjoy a range of up to 15 km, depending on riding conditions and user weight.",
        },
        {
          question:
            "What type of maintenance does the Lil E kick scooter require?",
          answer:
            "Regular maintenance includes checking the brakes and battery condition to ensure optimal performance.",
        },
        {
          question: "What types of surfaces can the Lil E kick scooter handle?",
          answer:
            "Lil E comes with 8.25-inch pneumatic tyres that are designed to provide a comfortable ride on various surfaces, including pavement and light trails.",
        },
      ],
      [
        {
          question: "What is the weight limit for riders?",
          answer:
            "The bike is designed to support riders up to 100 kg, ensuring a safe and comfortable ride.",
        },
        {
          question: "How long does it take to charge the battery?",
          answer:
            "The 7.8Ah lithium-ion battery takes approximately 4-5 hours to fully charge.",
        },
        {
          question: "Can I ride Lil E in the rain?",
          answer:
            "While the kick scooter is designed for various weather conditions, it is recommended to avoid heavy rain to protect electrical components.",
        },
        {
          question: "What is the weight of Lil E?",
          answer:
            "The lightweight aluminium alloy 6061 frame keeps the kick scooter’s weight around 12 kg, making it easy to carry and store.",
        },
        {
          question: "Is the Lil E kick scooter suitable for uphill rides?",
          answer:
            "Yes, the 250W motor provides sufficient power for tackling moderate inclines with ease.",
        },
        {
          question: "What is the range of Lil E on a single charge?",
          answer:
            "You can enjoy a range of up to 20 km, depending on riding conditions and user weight.",
        },
        {
          question:
            "What type of maintenance does the Lil E kick scooter require?",
          answer:
            "Regular maintenance includes checking the brakes and battery condition to ensure optimal performance.",
        },
        {
          question: "What types of surfaces can the Lil E kick scooter handle?",
          answer:
            "Lil E comes with 8.25-inch pneumatic tyres that are designed to provide a comfortable ride on various surfaces, including pavement and light trails.",
        },
      ],
    ],

    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/lilE/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/lilE/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/lilE/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/lilE/gallery/Artboard+4.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/lilE/gallery/Artboard+5.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/lilE/gallery/Artboard+6.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
  },
  {
    name: "t-rex-pro",
    meta: {
      title: "Best Electric Cycle Under 45000 | EMotorad T-Rex Pro",
      description:
        "Looking for the best electric cycle under 45000? The EMotorad T-Rex Pro is your answer. Experience eco-friendly commuting without compromising on quality.",
      canonicalTag: "https://www.emotorad.com/bikes/t-rex-pro",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "149"],
    },
    links: {
      amazon:
        "https://www.amazon.in/Handlebar-Integrated-Indicators-Removable-Aluminium/dp/B0DHS97TT8",
      flipkart:
        "https://www.flipkart.com/emotorad-t-rex-promtb-handlebar-integrated-display-light-indicators-deep-purple-29-inches-7-gear-lithium-ion-li-ion-electric-cycle/p/itmb4be8e0e04597?pid=ECYH4ZPVDWUHP349",
    },
    warranty: "Lifetime Warranty*",
    bikeSpecs: {
      range: "Up to 70",
      speed: "Up to 25",
      battery: "13",
    },
    usp: "18.5” Aluminium Alloy 6061 Frame",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Trex+Pro+Launch+Video+For+Event+(1).mp4",
      tagline: "King of All Terrains",
    },
    structure: {
      structureHeadline: "What makes T-Rex Pro Special",
      structureDescription:
        "T-Rex Pro is the perfect blend of innovation and performance, featuring a first-of-its-kind integrated handlebar display, a durable aluminium alloy frame, and a powerful 13Ah battery. With advanced features like front suspension, integrated front and rear lights, indicators, hazard and ambient lights, the T-Rex Pro ensures a smooth, safe, and enjoyable ride across all terrains.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex+pro/Trex%2Bpro%2BWeb%2BBanner%2B1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex+pro/Trex%2Bpro%2BWeb%2BBanner%2B2%2Bcopy.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex+pro/purple/t-rex-pro-purple-dimension.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex+pro/Blue/t-rex-pro-blue-dimesnion.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex+pro/green/t-rex-pro-green-dimesnion.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use the T-Rex Pro for my office commute?",
        answer:
          "Yes, the T-Rex Pro is perfect for office commutes with its smooth performance, comfortable design, and excellent range, making your daily rides easy and efficient.",
      },
      {
        question: "What features does the handlebar on the T-Rex Pro offer?",
        answer:
          "The T-Rex Pro features a dynamic handlebar that enhances safety and convenience with several essential functions. It includes an inbuilt horn, a flashlight for nighttime visibility, and an integrated headlight with high and low beam options. The wide display keeps you informed, while ambient lights match the bike's colour for a stylish touch. Built-in indicators allow for safe signalling, making the T-Rex Pro the world's first and most complete e-bike to ever exist!",
      },
      {
        question: "How is a Handlebar Integrated Display different?",
        answer:
          "The T-Rex Pro Handlebar Integrated Display offers a sleek, easy-to-read interface directly on the handlebar, providing real-time ride data, battery status, and control of riding modes, all within your line of sight for a more convenient and streamlined riding experience.",
      },
      {
        question: "Can I use the T-Rex Pro on mountainous terrains?",
        answer:
          "Yes, the T-Rex Pro is built to handle mountainous terrains with its robust frame, powerful motor, and multi-speed gears, offering a smooth and controlled ride even on challenging trails.",
      },
      {
        question: "Can my kids ride the T-Rex Pro?",
        answer:
          "Certainly! Depending on your child's age, height, and cycling experience, they may enjoy riding the T-Rex Pro electric cycle. Always prioritise safety and ensure your child is comfortable and capable of handling the e-cycle before letting them ride. It's essential to assess their abilities and provide guidance to ensure a fun and secure riding experience.",
      },
      {
        question: "How many gears does the T-Rex Pro cycle have?",
        answer:
          "The T-Rex Pro comes with 7-speed Shimano gears, allowing for easy shifting and better control on various terrains.",
      },
      {
        question:
          "Can I ride the T-Rex Pro electric cycle in rainy conditions?",
        answer:
          "Yes, the T-Rex Pro is designed to be water-resistant, making it safe to ride in rainy conditions. However, avoid deep water that could submerge the electrical components.",
      },
      {
        question: "How do I maintain the T-Rex Pro electric cycle?",
        answer:
          "Regular maintenance includes keeping the chain lubricated, checking tire pressure, and cleaning the bike. Ensure the battery is charged and store it in a dry place. Periodically inspect the brakes and gears for smooth functioning.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer:
          "The T-Rex Pro battery typically takes 4.6 hours to charge 80% and 5-7 hours to fully charge.",
      },
    ],
  },
  {
    name: "stx",
    meta: {
      title: "EMotorad ST-X, Best Electric Cycle for City Commute",
      description:
        "EMotorad ST-X, the best electric cycle for city commutes. Enjoy a smooth ride with advanced features, high tensile step-through steel frame, and unmatched convenience. Ride smarter today!",
      canonicalTag: "https://www.emotorad.com/bikes/t-rex-pro",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "149"],
    },
    links: {
      amazon:
        "https://www.amazon.in/EMotorad-Electric-Removable-Battery-Step-Through/dp/B0DJY8GT1D",
      flipkart:
        "https://www.flipkart.com/emotorad-st-x-unisex-city-cycle-7-65ah-li-ion-removable-battery-cluster-c2-display-28-inches-single-speed-lithium-ion-li-ion-electric/p/itma30c0d1958090",
    },
    warranty: "Up to 5 year warranty*",
    bikeSpecs: {
      range: "Up to 35",
      speed: "Up to 25",
      battery: "7.65",
    },
    usp: "High Tensile Step-Through Steel Frame",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Stx+Video++(1).mp4",
      tagline: "Ride the Revolution!",
    },
    structure: {
      structureHeadline: "What makes STX Special",
      structureDescription:
        "ST-X is the ultimate city bike, with a sleek design that slices through traffic and a battery that lasts all day. The 700X40C tyres are the secret weapon, gliding through potholes and rough roads like a pro. With its powerful motor and smooth suspension, ST-X is the perfect ride for city dwellers who refuse to be slowed down. It's not just an e-cycle; it's a statement—a declaration of independence from traffic jams and parking headaches.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/STX/Banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/STX/Banner2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/STX/STX_dimension+Blue.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/STX/STX_dimension+Beige.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Can I use ST-X for my office commute?",
        answer:
          "Yes, the ST-X is a city bike and is suitable for office commutes and daily errands. It provides a convenient and eco-friendly mode of transportation.",
      },
      {
        question: "Can I use ST-X on a daily basis?",
        answer:
          "Yes, ST-X is designed for daily use! It's perfect for your daily commute, running errands, or just having fun. Its long-lasting battery and durable design make it a great choice for regular use.",
      },
      {
        question: "Is it convenient for women?",
        answer:
          "Absolutely! ST-X is designed to be comfortable and easy to use for everyone, including women. Its step-through lightweight frame, adjustable seat, and easy-to-use controls make it a great choice for women of all ages and skill levels.",
      },
      {
        question: "Can my kids ride ST-X?",
        answer:
          "Certainly! Anyone with sufficient cycling skills can safely enjoy the ST-X. It is lightweight and is perfect for anyone above 4.8 ft. However, always prioritise safety and ensure your child is comfortable and has his helmet on.",
      },
      {
        question: "How many gears does the ST-X e-cycle have?",
        answer:
          "The ST-X e-cycle has a single-speed gear system, which means it has only one gear which allows the wheel to rotate freely when not pedalling. This simple low-maintenance design makes it perfect for casual and relaxed riding in flat urban areas.",
      },
      {
        question: "Can I ride the ST-X electric cycle in rainy conditions?",
        answer:
          "The ST-X can handle light to moderate rain and wet conditions. However, avoid flooding or submerging it in water, which may damage electrical components and compromise safety. Please exercise caution and avoid excessive water exposure to ensure your safety and the longevity of the e-cycle.",
      },
      {
        question: "How do I maintain the ST-X electric cycle?",
        answer:
          "Regular maintenance includes cleaning and lubricating the chain, checking tyre pressure, inspecting brakes, and ensuring all components are tightened and functioning properly. Periodic professional maintenance is also recommended for optimal performance.",
      },
      {
        question: "How long does it take to charge the battery?",
        answer: "The ST-X's battery takes 3-4 hrs to charge.",
      },
    ],
  },
  {
    name: "doodle-pro",
    meta: {
      title: "EMotorad ST-X, Best Electric Cycle for City Commute",
      description:
        "EMotorad ST-X, the best electric cycle for city commutes. Enjoy a smooth ride with advanced features, high tensile step-through steel frame, and unmatched convenience. Ride smarter today!",
      canonicalTag: "https://www.emotorad.com/bikes/t-rex-pro",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "149"],
    },
    links: {
      amazon:
        "https://www.amazon.in/EMotorad-Fat-Tire-Foldable-Electric-Mountain/dp/B0DSQ1PLGP?th=1",
      flipkart:
        "https://www.flipkart.com/emotorad-doodle-v3-20-inches-7-gear-lithium-ion-li-ion-electric-cycle/p/itm9b7ead2cb84c6",
    },
    warranty: "Lifetime warranty*",
    bikeSpecs: {
      range: "Up to 60",
      speed: "Up to 25",
      battery: "12.75",
    },
    usp: "Front, Rear Lights, Indicators & Hazard Lights ",

    bikeGif: {
      url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Doodle(Product+Page+7+Sec).mp4",
      tagline: "A Beast on Two Wheels.",
    },
    structure: {
      structureHeadline: "What makes Doodle Pro Special?",
      structureDescription:
        "The Doodle Pro isn’t just an e-bike—it’s your gateway to endless adventure. With its foldable design, powerful 36V motor, and a range of up to 60 km on PAS, it’s built to take you further, faster. Equipped with 7-speed Shimano gears, front suspension, and bright front and rear lights, it's compact, easy-to-carry frame makes travelling a breeze. Unfold freedom and ride the future.",
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle-pro/Doodle%2BWeb%2BBanner%2B1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle-pro/Doodle%2BWeb%2BBanner%2B2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle-pro/doodle%2BV3%2Bnoc%2Bdimension+1.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/doodle-pro/doodle%2BV3%2Bnoc%2Bdimension+2.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "Are fat tyres good for all-terrain?",
        answer:
          "Yes, fat tyres provide excellent traction and stability, making them perfect for all-terrain riding, including sand, snow, and rough trails.",
      },
      {
        question:
          "Can I easily fold the Doodle Pro electric cycle for storage?",
        answer:
          "Yes, the Doodle Pro is designed with a foldable frame, making it easy to store in tight spaces or transport.",
      },
      {
        question: "Can I use Doodle Pro to commute to work?",
        answer:
          "Absolutely! With its smooth ride, powerful motor, and foldable design, the Doodle Pro is ideal for daily commutes.",
      },
      {
        question: "Can I take the Doodle Pro on mountain trails?",
        answer:
          "Yes, the Doodle Pro is built for adventure with its fat tyres and sturdy frame, making it suitable for mountain trails.",
      },
      {
        question: "Can my kids ride Doodle Pro?",
        answer:
          "Yes, they can! As long as your kids are old enough and know how to ride safely, they can enjoy Doodle Pro too. Just make sure they're supervised and comfortable with the e-cycle before they ride.",
      },
      {
        question: "Is Doodle Pro only good for certain types of terrain?",
        answer:
          "No, Doodle Pro is versatile and can handle various terrains, including city streets, mountain trails, and off-road paths.",
      },
      {
        question: "How long does it take to charge Doodle Pro's battery?",
        answer:
          "It typically takes 5-7 hours to fully charge the Doodle Pro's battery, depending on usage.",
      },
      {
        question: "How many gears does Doodle Pro have?",
        answer:
          "The Doodle Pro comes equipped with 7-speed Shimano gears for smooth and flexible riding.",
      },
      {
        question: "Can I ride the Doodle Pro in the rain?",
        answer:
          "Yes, the Doodle Pro is designed to handle wet conditions, but avoid submerging the battery or motor in deep water.",
      },
      {
        question: "How do I take care of my Doodle Pro?",
        answer:
          "Regularly check the tyres, clean the bike, ensure the battery is charged, and store it in a dry place to keep your Doodle Pro in top condition.",
      },
    ],
  },
  {
    name: "xcape-g1",
    meta: {
      title: "EMotorad ST-X, Best Electric Cycle for City Commute",
      description:
        "EMotorad ST-X, the best electric cycle for city commutes. Enjoy a smooth ride with advanced features, high tensile step-through steel frame, and unmatched convenience. Ride smarter today!",
      canonicalTag: "https://www.emotorad.com/bikes/t-rex-pro",
      range: {
        title: "Electric Cycles With 50KM+ Range | EMotorad E-Bikes",
        description:
          "Elevate your riding experience with EMotorad's electric cycles. With a range exceeding 50KM, these bikes are your perfect partner for longer journeys.",
        canonicalTag: "https://www.emotorad.com/bikes/range/desire",
      },
    },
    reviews: {
      testimonials: [
        {
          image: "https://m.media-amazon.com/images/I/815C2BIA5tL._SL1600_.jpg",
          content:
            "Very good cycle it's running hot. Worth it for all age group. In future they may have more designs. RCB.",
          name: "	Revanth",
          rating: "4.6",
        },
        {
          image: "https://m.media-amazon.com/images/I/81K5NDLO1uL._SL1600_.jpg",
          content:
            "The Emotorod electric bicycle offers a smooth and comfortable ride with its efficient motor and sturdy frame. Its sleek design and lightweight build make it easy to maneuver in urban environments. The battery life provides ample range for daily commutes or leisurely rides. The bike's responsive brakes and reliable handling ensure safety on various terrains. Overall, the Emotorod electric bicycle delivers a reliable and enjoyable riding experience for users of all levels.Great product for school going kids and teens for daily commuting.",
          name: "Hitesh",
          rating: "4.4",
        },
        {
          image: "https://m.media-amazon.com/images/I/61uQO+aSXPL._SL1600_.jpg",
          content:
            "Not very difficult to assemble bike on your own after seeing video on YouTube. Make sure that your front break cable does not go touching the front tyre. You have to make sure that front cable does not touch the front tyre while turning the handle right or left. I have seen this flaw while doing the assembly.",
          name: "Ajay Singh",
          rating: "4.5",
        },
      ],
      productText:
        "More than 90% of our customers who have bought EMotorad products have loved them. Do take a look at what some of them have to say:",
      product: ["4.6", "149"],
    },
    links: {
      amazon:
        "https://www.amazon.in/EMotorad-Fat-Tire-Foldable-Electric-Mountain/dp/B0DSQ1PLGP?th=1",
      flipkart:
        "https://www.flipkart.com/emotorad-doodle-v3-20-inches-7-gear-lithium-ion-li-ion-electric-cycle/p/itm9b7ead2cb84c6",
    },
    warranty: "5 years*",
    bikeSpecs: {
      range: "Up to 100",
      speed: "Up to 25",
      battery: "Dual 10.2",
    },
    usp: "Front Light with Integrated Horn",
    // bikeGif: {
    //   url: "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/productVideos/Doodle(Product+Page+7+Sec).mp4",
    //   tagline: "Carry More, Worry Less",
    // },
    structure: {
      structureHeadline: "What makes G1 Special?",
      structureDescription:
        'The G1 isn’t just an e-cycle—it’s a powerhouse of durability and performance, designed to handle heavy loads and long rides with ease. Its high-tensile longtail steel frame supports up to 150 kg, while dual 48V 10.2Ah removable batteries provide an impressive range of up to 100 km on the pedal assist. Powered by a 250W rear hub motor and equipped with 24"x3.0" front and 20"x3.0" rear tyres for superior stability, the G1 delivers smooth, safe rides across any terrain. With features like 80mm front suspension, 180mm mechanical disc brakes, and a multifunctional display, it’s built for practicality, reliability, and everyday adventures.',
    },
    lifestyleImages: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/Cargo/banner1.jpg",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/Cargo/banner2.jpg",
    ],
    dimensionImage: [
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/Cargo/dimension+orange.png",
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/Cargo/dimension+black.png",
    ],
    gallery: [
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+1.jpg",
        imageDesc: '@qnami "I\'m having way too much fun on the CORE."',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+2.jpg",
        imageDesc:
          '@eric_tomlinson "The only bike that fits two comfortably. Zoe and I ride together everywhere!"',
      },
      {
        imageUrl:
          "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/productpage/t-rex/gallery/Artboard+3.jpg",
        imageDesc:
          '@nataliegagglund "Hello Jalama! Day one ripping the coastline with Drew!"',
      },
    ],
    faq: [
      {
        question: "What is the range of the G1?",
        answer:
          "The G1 provides up to 100 km on pedal assist, making it ideal for long-distance commutes and adventures.",
      },
      {
        question: "How much weight can the G1 carry?",
        answer:
          "The G1 can support up to 150 kg, making it perfect for carrying heavy loads or cargo.",
      },
      {
        question: "What type of motor does the G1 use?",
        answer:
          "The G1 is powered by a 250W rear hub motor, offering smooth and efficient performance, even with heavy loads.",
      },
      {
        question: "Are the batteries removable?",
        answer:
          "Yes, the G1 features dual 48V 10.2Ah removable batteries, providing flexibility for charging and extended range.",
      },
      {
        question: "Can the G1 handle rough terrains?",
        answer:
          'Absolutely! With its mullet tyre setup (24"x3.0" front and 20"x3.0" rear) and 80mm front suspension, the G1 is built for stability and comfort on rough and uneven paths.',
      },
      {
        question: "How long does it take to charge the G1’s batteries?",
        answer:
          "The batteries typically take 4-5 hours to fully charge, depending on usage.",
      },
      {
        question: "Is the G1 suitable for off-road riding?",
        answer:
          "Yes, the G1 is designed for off-road performance, with large tyres and front suspension for superior grip and comfort on challenging trails.",
      },
      {
        question: "What type of brakes does the G1 have?",
        answer:
          "The G1 is equipped with 180mm mechanical disc brakes with auto cut-off, ensuring reliable and controlled stopping.",
      },
      {
        question: "Can I ride the G1 in the rain?",
        answer:
          "Yes, the G1 is designed to handle wet conditions, but avoid submerging the motor or battery in deep water.",
      },
      {
        question: "What’s the height compatibility for riders?",
        answer:
          "The G1 is compatible with riders between 5’0” and 6’0” in height, providing a comfortable riding experience for most users.",
      },
    ],
  },
];
