import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const FormTestRideKerala = ({ gclid }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const navigate = useNavigate();

  const bookRide = async (e) => {
    e.preventDefault();
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const pageVisited = "cycle-vs-ecycle";
      const result = await axios.post(
        "/api/testride/bookaride",
        {
          name,
          email,
          phoneNumber,
          pageVisited,
          region: "malyalam",
          gclid,
        },
        config
      );

      if (result.status === 200) {
        navigate("/testride-scheduled/malyalam");
      } else {
        alert("Failed to book the test ride.");
      }
    } catch (error) {
      console.error("There was an error booking the ride:", error);
      alert("Server error. Please try again later.");
    }
  };

  return (
    <div className="w-100 px-3 px-lg-4">
      {/* <h3 className="text-center mb-4">Book Your Test Ride</h3> */}
      <form
        className="d-flex form-test-ride-container bg-light w-100 p-4 mt-3 rounded-3  justify-content-between align-content-center flex-column testRideForm "
        onSubmit={bookRide}
      >
        <h4
          className="text-center mb-3"
          style={{ fontFamily: "Metropolis-bold" }}
        >
          ഇലക്ട്രിക് ഫൺ അനുഭവിക്കുക
        </h4>
        <input
          type="text"
          className="form-control text-dark w-100 input-sm mb-3 "
          required
          value={name}
          autoComplete="off"
          style={{ background: "none" }}
          placeholder="പേര്"
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
        <input
          type="number"
          className="form-control text-dark w-100 input-sm mb-3"
          required
          value={phoneNumber}
          onChange={(e) => {
            const phoneNumber = e.target.value.slice(0, 12);
            setPhoneNumber(phoneNumber);

            if (/^[6-9]\d{9}$/.test(phoneNumber)) {
              e.target.setCustomValidity("");
            } else {
              e.target.setCustomValidity(
                "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
              );
            }
          }}
          onInvalid={(e) =>
            e.target.setCustomValidity(
              "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
            )
          }
          placeholder="ബന്ധപ്പെടേണ്ട നമ്പർ"
        />
        <div className="mb-3">
          <input
            type="email"
            className="form-control"
            placeholder="ഇമെയിൽ"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <button
          type="submit"
          style={{ background: "#004dac" }}
          className=" btn w-100 rounded-3  text-light"
        >
          ബുക്ക് ടെസ്റ്റ് റൈഡ്
        </button>
      </form>
    </div>
  );
};

export default FormTestRideKerala;
