import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import ReactPlayer from "react-player";
import { getAllBikes } from "../Actions/bikeActions";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

const UserManual = () => {
  const dispatch = useDispatch();
  const allBikes = useSelector((state) => state.allBikes);
  const { bikes } = allBikes;
  useEffect(() => {
    if (!bikes) {
      dispatch(getAllBikes());
    }
  }, []);
  const { name } = useParams();
  const bikeData = [
    {
      name: "X1",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X1+Blue.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1.pdf",
      assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
    },
    {
      name: "X2",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X2.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X2.pdf",
      assemblyVideoURL: "https://youtu.be/Bfkmxy2dhlo?si=ch-JzfslmjC59XdV",
    },
    {
      name: "X3",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X3.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X3.pdf",
      assemblyVideoURL: "https://youtu.be/hLbm8K9NF_0?si=IwnkiP4Q9SVxDFJp",
    },
    {
      name: "Neo",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Neo.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1+Neo.pdf",
      assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
    },
    {
      name: "X1 Limited Edition",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/X1+LTD+edition.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1.pdf",
      assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
    },
    {
      name: "Legend 07 MSD Edition",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/legend+07.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/X1-Dhoni+edition.pdf",
      assemblyVideoURL: "https://youtu.be/CdOTzLPeVlc?si=3ieZZkQlfs_WabaU",
    },
    {
      name: "EMX",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/EMX.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/emx.pdf",
      assemblyVideoURL: "https://youtu.be/WpntY8JLCJo?si=wCyrFTsYAyeBF_6q",
    },
    {
      name: "EMX+",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/EMX%2B.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/EMX%2B.pdf",
      assemblyVideoURL: "https://youtu.be/WpntY8JLCJo?si=wCyrFTsYAyeBF_6q",
    },
    {
      name: "Doodle V2",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Doodle+V2.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/doodle+V2.pdf",
      assemblyVideoURL: "https://youtu.be/GQLWFT-6ZWs?si=3jFHt0xisY5gR4qv",
    },
    {
      name: "Doodle V3",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Doodle+v3.mp4",
      userManual:
      "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing%20page/know-your-ecycle/usermanuals/Doodle%20V3.pdf",
      assemblyVideoURL: "https://youtu.be/GQLWFT-6ZWs?si=3jFHt0xisY5gR4qv",
    },
    {
      name: "Doodle Pro",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Doodle+v3.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/Doodle+Pro.pdf",
      assemblyVideoURL: "https://youtu.be/GQLWFT-6ZWs?si=3jFHt0xisY5gR4qv",
    },
    {
      name: 'T-Rex Air 27.5"',
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Trex+air.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/T-Rex+Air+27.5.pdf",
      assemblyVideoURL: "https://youtu.be/qdkltRtWOWI?si=PBHOifp1A3f5_HSk",
    },
    {
      name: 'T-Rex Air 29"',
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/Trex+air.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/T-Rex+Air+29.pdf",
      assemblyVideoURL: "https://youtu.be/qdkltRtWOWI?si=PBHOifp1A3f5_HSk",
    },
    {
      name: "T-Rex+ V3",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/trex%2B+red+gif.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/Trex%2B_User+Manual.pdf",
      assemblyVideoURL: "https://youtu.be/c7fxxN3n7uU?si=yjN4ZNiixur9SDPx",
    },
    {
      name: "T-Rex Pro",
      image:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/animation/T-Rex+Pro.mp4",
      userManual:
        "https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/usermanuals/T-Rex+Pro_User+Manual.pdf",
      assemblyVideoURL: "",
    },
  ];
  const bike = bikeData.find((b) => b.name === name);
  const [modalIsOpen, setIsOpen] = useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <>
      <Navbar />
      {bike && (
        <div>
          <div className="position-relative w-100" style={{ margin: "auto" }}>
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              className="modal-video"
              style={{
                overlay: {
                  backgroundColor: "rgba(0, 0, 0, 0.8)",
                  display: "flex",
                  zIndex: "100",
                  alignItems: "center",
                  justifyContent: "center",
                },
                content: {
                  position: "static",
                  margin: "auto",
                  border: "none",
                  padding: "0px",
                  outline: "none",
                  overflow: "hidden",
                },
              }}
              contentLabel="Video Modal"
            >
              <ReactPlayer
                url={bike.assemblyVideoURL}
                controls={true}
                playing={true}
                width="100%"
                height={window.innerWidth > 700 ? "100%" : "60%"}
              />
            </Modal>
          </div>
          <div className="user-manual">
            <div className="d-none d-lg-block">
              <div className="row px-5 py-3">
                <div className="col-6 p-0" style={{ margin: "auto" }}>
                  <div
                    className="d-flex pb-4 justify-content-center"
                    style={{ gap: "1.8em" }}
                  >
                    <div
                      className="bike-info-cards bike-info-cards-desktop p-3 d-flex justify-content-center align-items-center"
                      style={{
                        fontFamily: "Metropolis-bold",
                        cursor: "pointer",
                      }}
                    >
                      <Link
                        className="text-decoration-none text-center"
                        style={{ color: "inherit" }}
                        to={"/warranty"}
                      >
                        <Icon
                          icon="radix-icons:pencil-2"
                          width="2.5em"
                          height="2.5em"
                          style={{ color: "#004dac" }}
                        />
                        <p className="m-2">
                          Register your <br></br>E-bike
                        </p>
                      </Link>
                    </div>
                    <div
                      className="bike-info-cards bike-info-cards-desktop p-3 d-flex justify-content-center align-items-center"
                      style={{
                        fontFamily: "Metropolis-bold",
                      }}
                    >
                      <div className="text-center">
                        <a
                          href={bike.userManual}
                          download
                          className="text-decoration-none"
                          style={{ color: "inherit" }}
                        >
                          <Icon
                            icon="carbon:download"
                            width="2.5em"
                            height="2.5em"
                            style={{ color: "#004dac" }}
                          />
                          <p className="m-2 my-3">Download User Manual</p>
                        </a>
                      </div>
                    </div>
                    <div
                      className="bike-info-cards bike-info-cards-desktop p-3 d-flex justify-content-center align-items-center"
                      style={{
                        fontFamily: "Metropolis-bold",
                      }}
                      onClick={openModal}
                    >
                      <div
                        className="text-center"
                        style={{ cursor: "pointer" }}
                      >
                        <Icon
                          icon="bi:play-circle"
                          width="2.5em"
                          height="2.5em"
                          style={{ color: "#004dac" }}
                        />
                        <p className="m-2 my-3">Watch Assembly Video</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-6 text-center position-relative p-0">
                  <h1
                    className="text-center bike-heading-main mt-3 position-absolute"
                    style={{ fontFamily: "Metropolis-bold" }}
                  >
                    EMotorad {bike.name}
                  </h1>
                  {/* <img src={bike.image} className="w-75" alt={bike.name}></img> */}
                  <video
                    autoPlay
                    muted
                    loop
                    disableRemotePlayback
                    playsInline
                    className="gif-video img-fluid"
                  >
                    <source src={bike.image} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>
            <div className="d-lg-none text-center">
              <h1
                className="text-center bike-heading-main mt-4"
                style={{ fontFamily: "Metropolis-bold" }}
              >
                EMotorad {bike.name}
              </h1>
              <ReactPlayer
                url={bike.image}
                controls={false}
                loop={true}
                playing={true}
                playsinline={true}
                width="100%"
                height={window.innerWidth > 700 ? "100%" : "100%"}
              />
            </div>
            <div className="p-3 d-lg-none">
              <div
                className="bike-info-cards p-5 d-flex justify-content-center align-items-center mb-3"
                style={{
                  fontFamily: "Metropolis-bold",
                  height: "120px",
                  cursor: "pointer",
                }}
              >
                <Link
                  className="text-decoration-none"
                  style={{ color: "inherit" }}
                  to={"/warranty"}
                >
                  <p className="m-2">
                    Register your E-bike &nbsp;
                    <Icon
                      icon="radix-icons:pencil-2"
                      width="2.5em"
                      height="2.5em"
                      style={{ color: "#004dac" }}
                    />
                  </p>
                </Link>
              </div>
              <div className="d-flex w-100 pb-3" style={{ gap: "1em" }}>
                <div
                  className="w-50 bike-info-cards p-3 text-center"
                  style={{ fontFamily: "Metropolis-bold" }}
                >
                  <a
                    href={bike.userManual}
                    download
                    className="text-decoration-none"
                    style={{ color: "inherit" }}
                  >
                    <Icon
                      icon="carbon:download"
                      width="2.5em"
                      height="2.5em"
                      style={{ color: "#004dac" }}
                    />
                    <p className="m-2 my-3">Download User Manual</p>
                  </a>
                </div>
                <div
                  className="w-50 bike-info-cards p-3 text-center"
                  style={{ fontFamily: "Metropolis-bold", cursor: "pointer" }}
                  onClick={openModal}
                >
                  <Icon
                    icon="bi:play-circle"
                    width="2.5em"
                    height="2.5em"
                    style={{ color: "#004dac" }}
                  />
                  <p className="m-2 my-3">Watch Assembly Video</p>
                </div>
              </div>
            </div>
            <picture className="main-banner-img">
              <source
                media="(max-width:650px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/You're+all+set+(mobile).png"
              />
              <source
                media="(min-width:651px)"
                srcSet="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/You're+all+set+(Desktop).png"
              />
              <img
                src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/landing+page/know-your-ecycle/You're+all+set+(Desktop).png"
                alt="Banner1"
                className="d-block img-fluid"
              />
            </picture>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default UserManual;
