import React, { useEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import "../CSS/testride.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTestRide } from "../Actions/testrideAction";
import Meta from "../Components/Meta";
import Footer from "../Components/Footer";
import axios from "axios";
import ReactGAA from "react-ga4";

const TestRideScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [zip, setZip] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [gclid, setGclid] = useState("");

  const analyticEvent = (category, action) => {
    ReactGAA.event({
      category: category,
      action: action,
    });
  };

  useEffect(() => {
    // Fetching UTM campaign parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const campaign = urlParams.get("utm_campaign"); // Get the 'utm_campaign' parameter
    const GClid = urlParams.get("gclid");
    // Set the campaign name in the state
    if (campaign) {
      setUtmCampaign(campaign);
    }
    if (GClid) {
      setGclid(GClid);
    }
  }, []);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await axios.get("https://api64.ipify.org?format=json");
        if (response) {
          const clientIp = response.data.ip;
          const ipDataResponse = await axios.get(
            `https://ipapi.co/${clientIp}/json/`
          );

          setCity(ipDataResponse.data.city);
          setCountry(ipDataResponse.data.country);
          setZip(ipDataResponse.data.postal);
          setLatitude(ipDataResponse.data.latitude);
          setLongitude(ipDataResponse.data.longitude);
        }
      } catch (error) {
        console.error("Error fetching client IP:", error);
      }
    };

    fetchIPAddress();
  }, []);

  const bookTestride = async (event) => {
    try {
      event.preventDefault();

      dispatch(
        createTestRide(
          name,
          phoneNumber,
          city,
          country,
          zip,
          latitude,
          longitude,
          utmCampaign,
          gclid
        )
      );
      analyticEvent("Testride", "Testride Submitted Successfully");
      navigate("/testride-scheduled");
    } catch (error) {
      analyticEvent("Testride", "Testride Submission Failed");
      console.error("Error booking test ride:", error);
    }
  };
  return (
    <div>
      <Meta
        title={"Book Your Test Ride Now | EMotorad"}
        description={
          "EMotorad brings a range of the best electric bikes that are affordable &amp; futuristic. Crafted with the state of art components, at the best price. Book your test ride now!"
        }
        cannonicalTag={"https://emotorad.com/testride"}
      />
      <Navbar />
      <div className="testRideMainDiv d-none d-lg-block">
        <div className="testRideInnerDivDesktop ps-5 text-light">
          <h2
            className="testRideHeading mb-0"
            style={{ fontFamily: "Metropolis", fontSize: "2.3em" }}
          >
            <em>
              Ride the <span style={{ color: "#09E97E" }}>Mahi Way!</span>
            </em>
          </h2>
          <h1
            className=" testRideHeading"
            style={{ fontFamily: "Metropolis", fontSize: "2.1em" }}
          >
            <em>Book your test ride now.</em>
          </h1>
          <div className="w-75">
            <p
              className="mt-3"
              style={{
                fontFamily: "Metropolis-light",
                fontSize: "1rem",
                lineHeight: "1.3em",
              }}
            >
              <em>
                Discover the perfect electric cycle for your commute and
                adventure.
                <br></br>Ride an EMotorad electric bicycle today.
              </em>
            </p>
          </div>
          <form
            className="d-flex bg-light  p-3 mt-4 rounded-3  justify-content-between align-content-center flex-column testRideForm  "
            onSubmit={bookTestride}
          >
            <input
              type="text"
              className="form-control text-dark w-100 input-sm mb-3 "
              required
              value={name}
              autoComplete="off"
              style={{ background: "none" }}
              placeholder="Name"
              pattern="[A-Za-z\s]{2,}"
              onChange={(e) => {
                setName(e.target.value);
                e.target.setCustomValidity("");
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Name should have at least 2 characters and contain only alphabets."
                )
              }
            />
            <input
              type="number"
              className="form-control text-dark w-100 input-sm mb-3"
              required
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                setPhoneNumber(phoneNumber);

                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
              placeholder="Phone Number"
            />
            <button
              type="submit"
              style={{ background: "#004dac" }}
              className=" btn w-100  text-light"
            >
              Book Test Ride
            </button>
          </form>
        </div>
      </div>
      <div className="d-lg-none p-3">
        <div
          className="p-3 rounded"
          style={{ border: "1px solid #004dac", background: "#E9E9E9" }}
        >
          <div className="position-relative">
            <img
              src="https://ar-euro.s3.ap-south-1.amazonaws.com/india-webiste-17-02-24/testRide+Page/testRideBannerMobile.jpg"
              alt=""
              className="img-fluid w-100 rounded-3"
            />
            <div
              className="position-absolute text-light ps-4"
              style={{ top: "5%" }}
            >
              <h2
                className="mb-0"
                style={{ fontFamily: "Metropolis-medium", fontSize: "1.4em" }}
              >
                <em>
                  Ride the <span style={{ color: "#09E97E" }}>Mahi Way!</span>
                </em>
              </h2>
              <h1 className="" style={{ fontFamily: "", fontSize: "1.2em" }}>
                <em>Book your test ride now.</em>
              </h1>
              <div className="metropolis-light" style={{ width: "80%" }}>
                <p
                  className=""
                  style={{
                    fontFamily: "",
                    lineHeight: "1.2em",
                    fontSize: "0.72rem",
                  }}
                >
                  <em>
                    Discover the perfect electric cycle for your commute and
                    adventure.
                    <br></br>Ride an EMotorad electric bicycle today.
                  </em>
                </p>
              </div>
            </div>
          </div>

          <form className="  mt-4 rounded-3   " onSubmit={bookTestride}>
            <input
              type="text"
              className="form-control text-dark  w-100 input-sm mb-3 "
              required
              value={name}
              autoComplete="off"
              style={{}}
              placeholder="Name"
              pattern="[A-Za-z\s]{2,}"
              onChange={(e) => {
                setName(e.target.value);
                e.target.setCustomValidity("");
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Name should have at least 2 characters and contain only alphabets."
                )
              }
            />

            <input
              type="tel"
              className="form-control text-dark w-100 input-sm mb-3"
              required
              value={phoneNumber}
              onChange={(e) => {
                const phoneNumber = e.target.value.slice(0, 12);
                setPhoneNumber(phoneNumber);

                if (/^[6-9]\d{9}$/.test(phoneNumber)) {
                  e.target.setCustomValidity("");
                } else {
                  e.target.setCustomValidity(
                    "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                  );
                }
              }}
              onInvalid={(e) =>
                e.target.setCustomValidity(
                  "Enter a valid phone number (10 digits, starting with 6, 7, 8, or 9)"
                )
              }
              placeholder="Phone Number"
            />
            <button
              type="submit"
              style={{ background: "#004dac" }}
              className=" btn w-100  mt-2 p-2 text-light"
            >
              Book Test Ride
            </button>
          </form>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default TestRideScreen;
